<template>
    <div class="body-inner" v-if="user">
        <link href='plugins/datatables/datatables.min.css' rel='stylesheet' />
        <header-component></header-component>
        <section id="page-title" class="text-light" data-bg-parallax="images/compras_bg.jpg">
            <div class="container">
                <div class="page-title">
                    <h2>Catálogo de recursos</h2>
                    <span>Listado de recursos disponibles para compra</span>
                </div>
                <div class="breadcrumb">
                    <ul>
                        <li><a href="#">Inicio</a> </li>
                        <li><a href="#">Clientes</a> </li>
                        <li class="active"><a href="#">Catálogo de recursos</a> </li>
                    </ul>
                </div>
            </div>
        </section>
        <section id="page-content" class="no-sidebar">
            <div class="container">
                <!-- DataTable -->
                <div class="row mb-5">
                    <div class="col-lg-6">
                        <h4>Recursos para el ciclo escolar 2024</h4>
                        <!-- <p>Lorem ipsum, lorem ipsum. <a target="_blank" href="#">¿Dudas o comentarios?</a></p>-->
                    </div>
                    <div class="col-lg-6 text-right">
                        <!-- <button type="button" class="btn btn-light" @click.prevent="addProducts">
                            <i class="icon-plus"></i> 
                            Agregar
                        </button> -->
                        <!-- <button type="button" class="btn btn-success" @click.prevent="addProductsGo" v-if="$store.state.carrito.productos.length"> -->
                        <button type="button" class="btn btn-success" @click.prevent="addProductsGo">
                            {{ user.school.currency.key == 'USD' ? 'USD' : 'Q' }} Comprar
                        </button>
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-12">
                        <table id="datatable" class="table table-bordered table-hover" style="width:100%">
                            <thead>
                                <tr>
                                    <th>Seleccionar</th>
                                    <th>Recurso</th>
                                    <th>Nivel educativo</th>
                                    <th>Precio</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(producto, index) in productosCatalogo" :key="index">
                                    <td><input :value="producto.id + '//' + producto.level" v-model="products" type="checkbox"></td>
                                    <td>{{ producto.name }}</td>
                                    <td>{{ producto.level }}</td>
                                    <td>{{ user.tipo_usuario == 6 ? producto.dist_price : producto.sale_price | currency }}</td>
                                </tr>
                            </tbody>
                            <tfoot>
                                <tr>
                                    <th>Seleccionar</th>
                                    <th>Recurso</th>
                                    <th>Nivel educativo</th>
                                    <th>Precio</th>
                                </tr>
                            </tfoot>
                        </table>
                    </div>
                </div>
                <!-- end: DataTable -->
            </div>
        </section>
    </div>
</template>

<script>
/* eslint-disable */
import HeaderComponent from './components/HeaderComponent'
export default {
    name: 'Productos',
    components: {
    HeaderComponent
    },
    data() {
        return {
            productosCatalogo: [
                {
                    id: 0,
                    level: '',
                    level_id: 0,
                    levels: [],
                    name: '',
                    products: [],
                    sale_price: 0
                }],
            products: [],
            hostname: '',
            moment: window.moment,
            table: null,
            user: {
                school: {
                    currency: {}
                }
            },
        }
    },

    methods: {
        addProducts(){
            for (let i = 0; i < this.products.length; i++) {
                let splitedProduct = this.products[i].split('//')
                let product = this.productosCatalogo.find(e => (e.id == splitedProduct[0] && e.level == splitedProduct[1]))
                this.addProductCart(product)
            }

            Toast.fire({
                icon: 'success',
                title: 'Producto agregado al carrito.'
            })
        },
        addProductsGo(){
            this.addProducts()
            router.push({ name: "Carrito" })
        },
        addProductCart(model) {

            let producto = {
                product_id: model.id,
                name: model.name,
                level: model.level,
                level_id: model.level_id,
                quantity: 1,
                sale_price: user.tipo_usuario == 6 ? model.dist_price : model.sale_price
            }

            this.$store.commit('addProduct', producto)

            //verificamos si el usuario está logueado
            if(this.$store.getters.usuario) {
                this.saveDataNoForm('api/carrito', producto, '', '', null, 0, 0, null)
            }
        },
        async getProductsInner(url){
            await axios.get(apiKS + url, { headers: headers })
                .then( ({ data }) => { 
                    this.productosCatalogo = data
                    setTimeout( () => {
                        if (!$.fn.dataTable.isDataTable('#datatable')) {
                            if(this.table != null){
                                this.table.destroy();
                            }
                            this.table = $('#datatable').DataTable({
                                responsive: true
                            });
                        }
                    }, 500 )
                }).catch( error => {
                    this.catchError(error)
                })
        }
    },
    async beforeMount(){
        },
    async mounted() {
        var script = document.createElement('script')
        script.src = 'js/functions.js'
        document.body.appendChild(script)
        var len = $('script').filter(function () {
            return ($(this).attr('src') == 'plugins/datatables/datatables.min.js');
        }).length;
       
        if (len === 0) {
            script = document.createElement('script')
            script.src = 'plugins/datatables/datatables.min.js'
            document.body.appendChild(script)
        }

        setTimeout(async () => {
            this.user = window.user
            if(this.user) {
                this.openLoading()
                let headers = {
                    'Content-Type': 'application/json',
                    Authorization: localStorage.tokenETCSistema
                }
                if(window.user.tipo_usuario == 5)
                    await this.getProductsInner('api/distributor-products')
                else{
                    await this.getProductsInner('api/store-products')
                }
                Swal.close()
                if(window.user.tipo_usuario != 5 && window.user.tipo_usuario != 6 && window.user.tipo_usuario != 1){
                    Swal.fire({
                        icon: 'warning',
                        title: 'Recordatorio',
                        text: 'Por favor actualiza la información de los alumnos para ver los productos correspondientes.',
                        footer: '<a href="/store/perfil">Ir a mi cuenta</a>'
                    })
                }
    
                axios.get(apiKS + 'api/get-unconfirmed', { headers: headers }, this.model)
                    .then( response => {
                        if(response.data > 0){
                            Swal.fire({
                                icon: 'warning',
                                title: 'Recordatorio',
                                text: 'ACTUALICE EL GRADO DEL ALUMNO PARA VER LAS PLATAFORMAS  DEL CICLO ESCOLAR 2024.',
                                footer: '<a href="/store/perfil">Ir a mi cuenta</a>'
                            })
                        }
                    })
            }
    
        }, 1000);

    },
    updated(){
        
    }
}
</script>
