<template>
<div class="body-inner">
    <header-component></header-component>
    <section id="page-title" data-bg-parallax="images/login_bg.jpg">
        <div class="container">
            <div class="page-title">
                <h1>Portal de compras de ETC Iberoamérica</h1>
                <h3 class="text-white">Bienvenidos</h3>
                <span>Por favor inicie sesión<br> o registre una cuenta para ingresar al portal.</span>
            </div>
        </div>
    </section>
    <!-- COUNTERS -->
    <section>
        <div class="container mb-5">
            <div class="row">
                <div class="col-lg-5">
                    <div class="panel ">
                        <div class="panel-body text-center">
                            <h3>Iniciar sesión</h3>
                            <strong> Antes de iniciar, descarga el manual de uso de la tienda.</strong>
                            <br>
                            <a style="margin: 1rem 25%; text-align: center;" target="_blank" href="Manual ECommerce ETC Iberoamerica.pdf" class="btn btn-success">Descargar aquí</a>
                            <vue-form :state="formstate" @submit.prevent="onSubmit">
                                <div class="form-group">
                                    <validate tag="div">
                                        <label class="sr-only">Usuario</label>
                                        <input class="form-control" placeholder="Correo electrónico" type="email" name="email" id="email" v-model="model.email" required>
                                        <field-messages name="email" show="$invalid && $submitted" class="text-danger">
                                            <div slot="required">El correo electrónico es requerido</div>
                                            <div slot="email">El correo electrónico no es válido</div>
                                        </field-messages>
                                    </validate>
                                </div>
                                <div class="form-group m-b-5">
                                    <validate tag="div">
                                        <label class="sr-only">Password</label>
                                        <input class="form-control" placeholder="Contraseña" type="password" id="password" name="password" minlength="6" required v-model="model.password">
                                        <field-messages name="password" show="$invalid && $submitted" class="text-danger">
                                            <div slot="required">La contraseña es requerida</div>
                                            <div slot="minlength">La contraseña debe ser al menos de 8 caracteres</div>
                                        </field-messages>
                                    </validate>
                                </div>
                                <div class="form-group form-inline m-b-10 ">
                                    <div class="form-check">
                                        <label>
                                            <input type="checkbox" v-model="model.remember"><small class="m-l-10"> Mantener sesión iniciada</small>
                                        </label>
                                    </div>
                                </div>
                                <div class="text-center form-group">
                                    <button type="submit" class="btn">Ingresar</button>
                                </div>
                            </vue-form>
                        </div>
                    </div>
                    <p class="small">¿Aún no tiene cuenta? <router-link to="register">Registre una cuenta nueva</router-link></p>
                    <p class="small">¿No recuerdas tu password? <router-link to="password-recovery">Recuperar password</router-link></p>
                </div>
                <div class="col-lg-6 offset-1">
                    <iframe src="https://player.vimeo.com/video/864178638?h=0be657e737" width="640" height="340" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>
                </div>
            </div>
        </div>
    </section>
    <!-- end: SECTION GREY -->
</div>
</template>

<script>
/* eslint-disable */
import options from "../validations/validations.js";
Vue.use(VueForm, options);
import HeaderComponent from './components/HeaderComponent'
export default {
  name: 'Index',
  components: {
    HeaderComponent
  },
  data() {
      return {
        formstate: {},
        model: {
            email: '',
            password: '',
        },
        hostname: '',
        logo: 'images/logo-etc.png'
      }
  },

  methods: {
    onSubmit() {
        if (this.formstate.$invalid) {
            return;
        } else {
            this.openLoading()
            grecaptcha.ready(() => {
                grecaptcha.execute('6Lf6nd4ZAAAAAF0miQR-oaid9l9VrrUbkeNtSSGW', {action: 'submit'}).then((token) => {
                    this.model.captchaToken = token
                    axios.post(apiKS + 'api/login', this.model)
                    .then( response => {
                        if(response.data.id == 0 && response.data.token == '') {
                            Swal.close()
                            Swal.fire({
                                icon: 'error',
                                title: 'Oops...',
                                text: response.data.message,
                            })
                        }
                        if(response.data.id > 0 && response.data.token != '') {
                            Swal.close()
                            localStorage.tokenETCSistema = 'Bearer ' + response.data.token
                            headers = {
                                'Content-Type': 'application/json',
                                Authorization: localStorage.tokenETCSistema
                            }
                            axios.get(apiKS + 'api/user', { headers })
                            .then( response => {
                                localStorage.userETC = response.data.userEncrypted
                                axios.post(apiKS + 'api/userdecrypt', {usuario: localStorage.userETC}, { headers })
                                .then( response => {
                                    user = JSON.parse(response.data.user)
                                    localStorage.user = JSON.stringify(user);
                                    this.$store.commit('setUsuario', user)
                                    // axios.post(apiKS + 'api/crea-carrito', this.$store.state.carrito, { headers })
                                    // .then( response => {
                                        router.replace('/productos')
                                    // } )
                                    // .catch(error => {
                                    //     this.catchError(error)
                                    // })
                                }).catch(error => {
                                    this.catchError(error)
                                })
                            })
                        }
                    }).catch(error => {
                        this.catchError()
                    })
                })
            })
        }
    }
  },
  mounted() {
    const script = document.createElement('script')
    script.src = 'js/functions.js'
    document.body.appendChild(script)
  },
  created() {

  },
    beforeMount() {
    }
}
</script>
