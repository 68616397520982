<template>
    <div class="body-inner">
        <header-component></header-component>
        <section id="page-title" data-bg-parallax="images/completa_bg.jpg">
            <div class="container">
                <div class="page-title">
                    <h1>Compra completa</h1>
                </div>
                <div class="breadcrumb">
                    <ul>
                        <li><a href="#">Inicio</a>
                        </li>
                        <li><a href="#">Clientes</a>
                        </li>
                        <li><a href="#">Carrito</a>
                        </li>
                        <li class="active"><a href="#">Compra completa</a>
                        </li>
                    </ul>
                </div>
            </div>
        </section>
        <!-- end: Page title -->
        <!-- COMPRA COMPLETA -->
        <section id="shop-checkout-completed">
            <div class="container">
                <div class="p-t-10 m-b-20 text-center">
                    <div class="text-center">
                        <h3>Gracias. Su pago ha sido procesado con éxito.</h3>
                        <p>El número de su pedido es <a href="" class="text-underline"><mark>{{ $route.params.order }}</mark></a>. 
                        Recibirá un correo electrónico con el resumen de su compra e información general sobre los recursos adquiridos.</p>
                    </div>
                    <!-- <a href="#" class="btn icon-left m-r-10">
                        <span>
                            <i class="fa fa-print"></i> 
                            Imprimir pantalla
                        </span>
                    </a> -->
                    <router-link class="btn btn-warning icon-left" to="/compras">
                        <span><i class="fa fa-shopping-cart"></i> 
                        Ir a historial</span>
                    </router-link>
                </div>
            </div>
        </section>
        <!-- end: COMPRA COMPLETA -->
        <!-- INFO FACTURA -->
        <section class="background-grey p-t-40 p-b-0">
            <div class="container">
                <div class="row">
                    <div class="col-lg-4 mx-auto">
                        
                    </div>
                   
                   
                </div>
            </div>
        </section>
        <!-- end: SECTION GREY -->
    </div>
</template>

<script>
/* eslint-disable */
import options from "../validations/validations.js";
Vue.use(VueForm, options);
import HeaderComponent from './components/HeaderComponent'
export default {
  name: 'Index',
  components: {
    HeaderComponent
  },
  data() {
      return {
        formstate: {},
        model: {
            email: '',
            password: '',
        },
        hostname: '',
        logo: 'images/logo-etc.png'
      }
  },

  methods: {
    onSubmit() {
        if (this.formstate.$invalid) {
            return;
        } else {
            this.openLoading()
            axios.post(apiKS + 'api/login', this.model)
            .then( response => {
                if(response.data.id == 0 && response.data.token == '') {
                    Swal.close()
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: response.data.message,
                    })
                }
                if(response.data.id > 0 && response.data.token != '') {
                    Swal.close()
                    localStorage.tokenETCSistema = 'Bearer ' + response.data.token
                    headers = {
                        'Content-Type': 'application/json',
                        Authorization: localStorage.tokenETCSistema
                    }
                    axios.get(apiKS + 'api/user', { headers })
                    .then( response => {
                        localStorage.userETC = response.data.userEncrypted
                        axios.post(apiKS + 'api/userdecrypt', {usuario: localStorage.userETC}, { headers })
                        .then( response => {
                            user = JSON.parse(response.data.user)
                            this.$store.commit('setUsuario', user)
                            axios.post(apiKS + 'api/crea-carrito', this.$store.state.carrito, { headers })
                                .then( response => {
                                    router.replace('/productos')
                                } )
                                .catch(error => {
                                    this.catchError(error)
                                })
                        }).catch(error => {
                            this.catchError(error)
                        })
                    })
                }
            }).catch(error => {
                this.catchError()
            })
        }
    }
  },
  mounted() {
    const script = document.createElement('script')
    script.src = '/store/js/functions.js'
    document.body.appendChild(script)
  },
  created() {
      
  },
    beforeMount() {
    }
}
</script>
