<template>
    <div class="body-inner">
        <header-component></header-component>
        <!-- DETALLE DE PRODUCTO -->
        <!-- DETALLE DE PRODUCTO -->
        <section id="product-page" class="product-page p-b-0">
            <div class="container">
                <div class="product">
                    <div class="row m-b-40" v-if="model.imagen">
                        <div class="col-lg-2">
                            <div class="product-image">
                                <img :src="model.imagen">
                            </div>
                        </div>
                        <div class="col-lg-10">
                            <div class="product-description">
                                <div class="product-category"></div>
                                <div class="product-title">
                                    <h3>
                                        <a href="#">{{ model.name }}</a>
                                    </h3>
                                </div>
                                <!-- <p>{{ model.descripcion }}</p> -->
                                <p>Productos incluidos: </p>
                                <div class="row">
                                    <div class="card col-sm-12 col-md-3 col-lg-3 m-1" v-for="product in model.products" :key="product.key">
                                        <div class="card-header">
                                            <div class="card-title">{{ product.name }}</div>
                                        </div>
                                        <div class="card-body">
                                            <img :src="product.image" class="container">
                                            <p>{{ product.description }}</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="seperator m-t-20 m-b-10"></div>
                            </div>
                            <div class="row">
                                <div class="col-lg-6">
                                    <h5>Total</h5>
                                    <span class="amount color lead"><strong>{{ new Intl.NumberFormat("es-MX", {style: "currency", currency: "MXN"}).format(model.sale_price) }}</strong></span><br><br>
                                </div>
                                <div class="col-lg-4">
                                    <button class="btn btn-success" @click.prevent="addProductCart"><i class="icon-shopping-cart"></i> Agregar a carrito</button><br>
                                    <router-link class="btn" to="/productos">Regresar al catálogo</router-link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <!-- end: SHOP PRODUCT PAGE -->
        <!-- Footer -->
        <footer id="footer">
            <div class="copyright-content">
                <div class="container">
                    <div class="copyright-text text-center">&copy; 2020 <a href="https://etciberoamerica.com/" target="_blank" rel="noopener">ETC IBEROAMÉRICA.</a> </div>
                </div>
            </div>
        </footer>
        <!-- end: Footer -->
    </div>
</template>

<script>
/* eslint-disable */
import HeaderComponent from './components/HeaderComponent'
export default {
  name: 'DetalleProducto',
  components: {
    HeaderComponent
  },
  data() {
      return {
        model: {
            imagen: true,
            products: []
        },
        form: {
            total: 0,
            codigoCupon: '',
        }
      }
  },

  methods: {

    consultaPrecio(id_version, tipo) {
        const arrayPrecios = this.model.precios
        const precios = arrayPrecios.filter(precio => precio.id_version == id_version && precio.tipo == tipo)
        const descuento = this.$store.getters.descuento / 100
        const precio = precios[0].precio - (precios[0].precio * (descuento))
        $("#precio" + id_version).val(precio)
        $("#precioText" + id_version).text(new Intl.NumberFormat("es-MX", {style: "currency", currency: "MXN"}).format(precio))
        this.calculaPrecio()
    },

    enableTipoCompra(id_version) {
        this.$forceUpdate()
        const precios = this.model.precios.filter(precio => precio.id_version == id_version)
        if($("#check" + id_version).prop('checked') != true)
            $("#precio" + id_version).val('')
        else {
            for (let index = 1; index <= 3; index++) {
                if($("#tipoCompra" + id_version + `_${index}`).is(':checked') == true) {
                    this.consultaPrecio(id_version, index)
                }
            }
        }

        this.calculaPrecio()
    },

    calculaPrecio() {
        const versiones = this.model.versiones
        let total = this.$store.getters.total
        /*versiones.forEach((element) => {
            if($("#precio" + element.id).val() != ''){
                total = total + parseFloat($("#precio" + element.id).val())
            }
        })*/
        total = total + this.model.sale_price
        this.form.total = total
        this.$store.commit("setTotal", (total))
    },

    addProductCart() {
        const versiones = this.model.versiones
        let producto = {
            product_id: this.model.id,
            name: this.model.name,
            quantity: 1,
            sale_price: this.model.sale_price
        }

        this.$store.commit('addProduct', producto)

        //verificamos si el usuario está logueado
        if(this.$store.getters.usuario) {
            this.saveDataNoForm('api/carrito', producto, '', '', null, 0, 0, null)
        }

        Toast.fire({
            icon: 'success',
            title: 'Producto agregado al carrito.'
        })
    },

  },
  async mounted() {
    // const script = document.createElement('script')
    // script.src = 'js/functions.js'
    // document.body.appendChild(script)

    await this.loadData(`api/licenses/${this.$route.params.id}`)

  },
}
</script>
<style>
    .none{
        display: none;
    }
</style>