<template>
    <div class="body-inner">
        <header-component></header-component>

        <section id="page-title" data-bg-parallax="/images/checkout_bg.jpg">
            <div class="container">
                <div class="page-title">
                    <h1><strong>Detalle de compra</strong></h1>
                </div>
                <div class="breadcrumb">
                    <ul>
                        <li><router-link to="/">Inicio</router-link>
                        </li>
                        <li><router-link to="/">Clientes</router-link>
                        </li>
                        <li><router-link to="/compras">Compras</router-link>
                        </li>
                        <li class="active">
                            <a href="#">Detalle de compra</a>
                        </li>
                    </ul>
                </div>
            </div>
        </section>
        <!-- DETALLE DE PRODUCTO -->
        <section id="product-page" class="product-page p-b-0">
            <div class="container">
                <div class="product">
                    <div class="row m-b-40">
                        <div class="col-lg-2">
                            <div class="product-image">

                            </div>
                        </div>
                        <div class="col-lg-10">
                            <div class="product-description">
                                <div class="product-category"></div>
                                <div class="product-title">
                                    <h3>
                                        <a href="#">Detalle de la orden #{{ model.key }}</a>
                                    </h3>
                                </div>
                                <div class="product-price"></div>
                                <p>Fecha: {{ model.created_at.split('T')[0] }}</p>
                                <p>Monto total: {{ model.total | currency }} {{ user.school.currency.key }}</p>
                                <p v-if="user.tipo_usuario < 5">Estatus: {{ status[model.status] }}</p>
                                <p v-if="user.tipo_usuario >= 5">Estatus: {{ statusReseller[model.status] }}</p>
                                <div class="seperator m-t-20 m-b-10"></div>
                                <div class="row">
                                    <div class="col-md-12">
                                        <div class="table table-sm table-striped table-responsive">
                                            <table class="table" v-if="user.tipo_usuario < 5 || $route.query.po == 0">
                                                <thead>
                                                    <tr>
                                                        <th class="cart-product-thumbnail">Producto</th>
                                                        <th class="cart-product-price">Cantidad</th>
                                                        <th class="cart-product-thumbnail">Precio unitario</th>
                                                        <th class="cart-product-thumbnail">Asignados</th>
                                                        <th class="cart-product-thumbnail">Acciones</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr v-for="compra in model.products" :key="compra.id">
                                                        <td>{{ compra.license.name }} {{ compra.level.name }}</td>
                                                        <td>{{ compra.quantity }}</td>
                                                        <td> {{ compra.license.sale_price | currency }} {{ user.school.currency.key }}</td>
                                                        <td>{{ compra.beneficiaries.length }}</td>
                                                        <td>
                                                            <a data-toggle="collapse" href="#collapse" role="button" aria-expanded="false" aria-controls="collapse" @click="setProduct(compra)">
                                                                <i class="fa fa-search"></i> Ver detalle
                                                            </a>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                            <table class="table" v-else>
                                                <thead>
                                                    <tr>
                                                        <th class="cart-product-thumbnail">Producto</th>
                                                        <th class="cart-product-price">Cantidad</th>
                                                        <th class="cart-product-thumbnail">Precio unitario</th>
                                                        <th class="cart-product-thumbnail">Total</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr v-for="compra in model.products" :key="compra.id">
                                                        <td>{{ compra.product_dist ? compra.product_dist.product.license.name : compra.product.name }} {{ compra.level ? compra.level.name : '' }}</td>
                                                        <td>{{ compra.quantity }}</td>
                                                        <td>{{ (compra.product_dist ? compra.product_dist.product.license.sale_price : compra.unit_price) | currency }} {{ user.school.currency.key }}</td>
                                                        <td>{{ (compra.product_dist ? compra.product_dist.product.license.sale_price * compra.quantity : compra.unit_price * compra.quantity) | currency }} {{ user.school.currency.key }}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                            <div class="collapse" id="collapse" v-if="product.id != null">
                                                <table class="table">
                                                    <thead>
                                                        <th class="text-center">#</th>
                                                        <th>Nombre beneficiario</th>
                                                        <th>Nivel educativo</th>
                                                        <th>Código</th>
                                                        <th></th>
                                                    </thead>
                                                    <tbody>
                                                        <tr v-for="(benefit, index) in product.beneficiaries" :key="benefit.id">
                                                            <td class="text-center">{{ index + 1 }}</td>
                                                            <td v-if="!benefit.edit">{{ benefit.benefitiary.name }}</td>
                                                            <td v-if="!benefit.edit">{{ getLevel(benefit.benefitiary.level_id).name }}</td>
                                                            <td v-if="!benefit.edit">{{ benefit.code }}</td>
                                                            <td v-if="benefit.edit">
                                                                <v-select :options="benefitiaries[product.level_id]" v-model="benefit.id" label="name"></v-select>
                                                            </td>
                                                            <td v-if="benefit.edit"></td>
                                                            <td v-if="benefit.edit"></td>
                                                            <td></td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                                <button class="btn btn-default float-right" @click.prevent="addBenefit" v-if="product.beneficiaries.length < product.quantity">
                                                    <i class="fa fa-plus"></i>
                                                    Agregar
                                                </button>
                                                <button class="btn btn-success float-right mr-2" @click.prevent="storeBenefitiaries" v-if="editMode">
                                                    <i class="fa fa-save"></i>
                                                    Guardar
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
/* eslint-disable */
import HeaderComponent from './components/HeaderComponent'
export default {
    name: 'DetalleCompras',
    components: {
    HeaderComponent
    },
    data() {
        return {
            benefitiaries: [],
            editMode: false,
            model: {
                key: '',
                created_at: '',
                status: '',
                total: 0,
                products: [{
                    level: {
                        name: ''
                    },
                    license: {
                        name: ''
                    },
                    beneficiaries: []
                }]
            },
            product: {
                benefitiaries: []
            },
            status: [
                'Pendiente',
                'Pagada',
                'Entregada',
                'Anulada'
            ],
            statusReseller: [
                '',
                'Pendiente de pago',
                'Pagada',
                'Entregada',
                'Anulada'
            ],
            user: {
                benefitiaries: [],
                school: {
                    currency: {}
                }
            }
        }
    },

    methods: {
        addBenefit(){
            if(!this.product.beneficiaries.length){
                this.editMode = true

                this.product.beneficiaries.push({
                    name: '',
                    edit: true
                })
            }else{
                if(this.product.beneficiaries[this.product.beneficiaries.length-1].name != ''){
                    if(this.product.beneficiaries.length < this.product.quantity){
                        this.editMode = true

                        this.product.beneficiaries.push({
                            name: '',
                            edit: true
                        })
                    }
                }else{
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Debes agregar el nombre del último beneficiacio',
                    })
                }
            }
        },
        getLevel(level_id){
            return this.levels.find(e => e.id == level_id)
        },
        async getLevels() {
            await axios.get(apiKS + 'api/public/levels/' + window.user.school_id, { headers: headers })
                .then( ({ data }) => (this.levels = data))
                .catch( error => {
                    this.catchError(error)
                })
        },
        moment: function () {
            return moment();
        },
        removeBeneficiary(index){
            this.product.benefitiaries.splice(index, 1)
        },
        async storeBenefitiaries(){
            await this.storeBenefits(this.product.id, this.product)
        },
        setProduct(compra){
            this.product = compra
        }
    },

    async mounted() {
        await this.loadData(`api/orders/${this.$route.params.id}?po=${this.$route.query.po}`)
        this.getLevels()

        this.user = this.$store.getters.usuario

        this.benefitiaries = []
        if(this.user.benefitiaries.length){
            for(let i = 0; i < this.user.benefitiaries.length; i++){
                if(this.benefitiaries[this.user.benefitiaries[i].level_id] == undefined){
                    this.benefitiaries[this.user.benefitiaries[i].level_id] = []
                }
                this.benefitiaries[this.user.benefitiaries[i].level_id].push(this.user.benefitiaries[i])
            }
        }
    },
}
</script>
