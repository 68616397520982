<template lang="pug">
    div.body-inner
        header-component
        section#page-title(data-bg-parallax="images/registro_bg.jpg")
            div.container
                div
                    h1 Portal de compras
                        br
                        template de ETC Iberoamérica
                    h3 Actualización de cuenta
        section
            div.container
                div.row
                    div.col-lg-8.center.no-padding
                        vue-form.form-transparent-grey(:state="formstate" @submit.prevent="update()" autocomplete="off")
                            div.row
                                div.col-lg-12
                                    h3 Actualización de datos
                                div.col-lg-6.form-group
                                    validate(tag="div")
                                        label(class="sr-only") Nombre del padre o tutor
                                        input#nombres.form-control(type="text" autocomplete="off" v-model="model.nombre" placeholder="* Nombre(s)" required name="nombres")
                                        field-messages.text-danger(name="nombres" show="$invalid && $submitted")
                                            div(slot="required") El nombre es requerido
                                div.col-lg-6.form-group
                                    validate(tag="div")
                                        label.sr-only Apellido
                                        input#apellidos.form-control(type="text" v-model="model.apellidos" placeholder="* Apellido(s)" required name="apellidos")
                                        field-messages.text-danger(name="apellidos" show="$invalid && $submitted")
                                            div(slot="required") Los apellidos son requeridos
                                div.col-lg-6.form-group
                                    validate(tag="div")
                                        label.sr-only Contraseña
                                        input#password.form-control(type="password" v-model="model.password" placeholder="* Contraseña" name="password" minlength="8")
                                        field-messages.text-danger(name="password" show="$invalid && $submitted")
                                            div(slot="minlength") La contraseña debe de tener mínimo 8 caracteres
                                div.col-lg-6.form-group
                                    validate(tag="div")
                                        label.sr-only Confirma Contraseña
                                        input#password.form-control(type="password" v-model="model.password_confirmation" placeholder="* Confirma Contraseña" name="password_confirmation" minlength="8")
                                div.col-lg-6.form-group
                                    validate(tag="div")
                                        label.sr-only Correo electrónico
                                        input.form-control#email(type="email" v-model="model.email" placeholder="* Correo electrónico" required name="email")
                                        field-messages.text-danger(name="email" show="$invalid && $submitted")
                                            div(slot="required") El correo electrónico es requerido
                                            div(slot="email") El correo electrónico no es válido
                                div.col-lg-6.form-group
                                    validate(tag="div")
                                        label.sr-only Teléfono
                                        input.form-control(type="text" v-model="model.telefono" placeholder="Teléfono" name="telefono" id="telefono")
                                div.col-lg-12.form-group
                                    validate(tag="div")
                                        label.sr-only Institución
                                        v-select#schools(:options="schools" label="name" v-model="model.school_id" name="school" placeholder="Institución")
                            div.card
                                .row.p-2(v-for="(user, index) in model.user" :key="index")
                                    div.col-lg-5.form-group
                                        label.sr-only Registro de estudiantes
                                        input.form-control(type="text" value="" v-model="user.name" placeholder="Nombre de estudiante")
                                    div.col-lg-5.form-group
                                        label.sr-only Nivel educativo
                                        select.form-control#exampleFormControlSelect1(v-model="user.level" placeholder="Nivel Educativo")
                                            option(value="") Selecciona un nivel educativo
                                            option(v-for="level in levels" :value="level.id") {{ level.name }}
                                    div.col-lg-2.form-group
                                        a.btn.btn-danger(@click.prevent="deleteBenefitiary(user, index)")
                                            i.fa.fa-trash
                                .row.align-right
                                    .col-lg-8
                                    .col-lg-4
                                        a.btn-link(@click.prevent="addUser" v-if="(user.tipo_usuario != 6 && model.user.length < 10) || (user.tipo_usuario == 6)" href="#")
                                            i.fa.fa-plus
                                            template Agregar otro estudiante
                            .row
                                div.seperator
                                    i.fa.fa-file-invoice
                                div.col-lg-12
                                    h5 Información de facturación
                                    br
                                    small Por disposición oficial para la emisión de facturas con montos superiores a Q2,500 se requerirá el NIT, DIP o número de pasaoporte en caso de ser una entidad extranjera.
                                    br
                                    small Por favor introduce el NIT sin guiones (-)
                                div.col-lg-12.form-group.row
                                    .form-check.col-lg-3.text-center
                                        input.form-check-input(type="radio" value="nit" v-model="model.nit_type" name="nit_type")
                                        label.form-check-label NIT
                                    .form-check.col-lg-3.text-center
                                        input.form-check-input(type="radio" value="dpi" v-model="model.nit_type" name="nit_type")
                                        label.form-check-label DPI
                                    .form-check.col-lg-3.text-center
                                        input.form-check-input(type="radio" value="passport" v-model="model.nit_type" name="nit_type")
                                        label.form-check-label Pasaporte
                                    .form-check.col-lg-3.text-center
                                        input.form-check-input(type="radio" value="external" v-model="model.nit_type" name="nit_type")
                                        label.form-check-label Extranjero 
                                div.col-lg-4.form-group
                                    label.sr-only Número de identificación
                                    input.form-control(type="text" value="" v-model="model.nit" placeholder="NIT" @change="checkNIT")
                                div.col-lg-4.form-group
                                    label.sr-only Nombre para facturación
                                    input.form-control(type="text" value="" v-model="model.fiscal_name" placeholder="Nombre para facturación" name="fiscal_name")
                                div.col-lg-4.form-group
                                    label.sr-only Email
                                    input.form-control(type="text" value="" v-model="model.fiscal_email" placeholder="Email")
                                //-div.col-lg-4.form-group
                                    label.sr-only Calle y número
                                    input.form-control(type="text" value="" v-model="model.address" placeholder="Calle y número")
                                //-div.col-lg-4.form-group
                                    label.sr-only Departamento
                                    select.form-control#state_id(v-model="model.state_id" placeholder="Departamento" @change="getCities")
                                        option(value="0") Selecciona un departamento
                                        option(v-for="state in states" :value="state.id") {{ state.name }}
                                //-div.col-lg-4.form-group
                                    label.sr-only Municipio
                                    select.form-control#city_id(v-model="model.city_id" placeholder="Municipio")
                                        option(value="0") Selecciona un municipio
                                        option(v-for="city in cities" :value="city.id") {{ city.name }}
                                //-div.col-lg-4.form-group
                                    label.sr-only Código postal
                                    input.form-control(type="text" value="" v-model="model.cp" placeholder="Código postal")
                                div.seperator
                                div.col-lg-6.form-group
                                div.col-lg-6.form-group
                                    button.btn.float-right(type="submit") Actualizar cuenta
                                    
</template>
<script>
/* eslint-disable */
import options from "../validations/validations.js";
import headerComponent from '../pages/components/HeaderComponent'
Vue.use(VueForm, options);
export default {
    name: 'Registro',
    components: {
        headerComponent
    },
    data() {
        return {
            apiKS: window.apiKS,
            countries: [],
            cities: [],
            levels: [],
            schools: [],
            states: [],
            formstate: {},
            model: {
                nombre: '',
                apellidos: '',
                email: '',
                nit: '',
                password: '',
                id_pais: '',
                id_idioma: '',
                state_id: '0',
                city_id: '0',
                cp: '',
                fecha_nacimiento: '',
                tipo_usuario: {id: '4'},
                telefono: '',
                nit_type: 'nit',
                user: [
                    {
                        grade: 'Primero',
                        level: 'Preescolar'
                    },
                ]
            },
            hostname: '',
            logo: 'images/logo-etc.png',
            user: user
        }
    },

    methods: {
        addUser(){
            let find = this.checkBenefitiaries()
            if(find == null){
                if((this.user.tipo_usuario != 6 && this.model.user.length < 10) || (this.user.tipo_usuario == 6)){
                    this.model.user.push({
                            grade: 'Primero',
                            level: 'Preescolar'
                        })
                    this.$forceUpdate()
                }
            }else{
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Existen alumnos beneficiarios repetidos',
                })
            }
        },
        checkBenefitiaries(){
            let find = null
            for(let i = 0; i < this.model.user.length; i++){
                find = this.model.user.find((obj, index) => { return (index != i && obj.name == this.model.user[i].name && obj.level == this.model.user[i].level) ? true : false  })
            }

            return find
        },
        deleteBenefitiary(user, index){
            if(this.model.user.length > 1){
                this.dropBenefitiary(user, index)
                this.model.user.splice(index, 1)
                headers = {
                    'Content-Type': 'application/json',
                    Authorization: localStorage.tokenETCSistema
                }
                axios.get(apiKS + 'api/user', { headers }).then(response => {
                    localStorage.userETC = response.data.userEncrypted
                })
                this.$store.commit('setUsuario', this.model)
                this.$forceUpdate()
            }else{
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Debe de existir por lo menos un estudiante',
                })
            }
        },
        async update() {
            if (this.formstate.$invalid) {
                return;
            } else {
                let find = this.checkBenefitiaries()
                if(find == null){
                    await this.updateProfile('api/public/update-users', this.model, '/perfil', '', null, 0, 1, null)
                    localStorage.setItem('user', JSON.stringify(user))
                }else{
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Existen alumnos beneficiarios repetidos',
                    })
                }
            }
        },
        async getLevels(){
            if(this.model.school_id){
                await this.getLevelSchool(this.model.school_id.id)
            }
        },
        async getCities(){
            await this.getCitiesPublic(this.model.state_id)
        },
        async getStates(){
            await this.getStatesPublic(1)
        },
        checkNIT(){
            if(this.model.nit != 'CF' && this.model.nit_type == 'nit'){
                this.openLoading()
                axios.get(apiKS + 'api/public/check-nit', { params: { nit: this.model.nit }}).then(response => {
                    if(response.data.Result == 'true'){
                        Swal.close()
                        this.model.fiscal_name = response.data.nombre
                        this.$forceUpdate()
                    }else{
                        Swal.close()
                        Swal.fire({
                            icon: 'error',
                            title: 'Oops...',
                            text: response.data.error,
                        })
                    }
                })
            }
        },
    },
    async mounted() {
        const script = document.createElement('script')
        script.src = 'js/functions.js'
        document.body.appendChild(script)
        this.openLoading()

        this.hostname = hostname
        if(this.user == null){
            this.user = JSON.parse(localStorage.getItem('user'))
        }
        await this.getCountriesPublic()
        await this.getSchools()
        this.model = this.user
        this.model.user = this.user.benefitiaries
        this.model.school_id = this.user.school
        await this.getLevels()
        await this.getStates()
        
        for(let i = 0; i < this.model.user.length; i++){
            // this.model.user.level = this.levels.find(e => e.id == this.model.user.level_id)
            this.model.user[i].level = this.model.user[i].level_id
        }
        this.$forceUpdate()
        Swal.close()
    },
}
</script>
