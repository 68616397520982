<template>
    <div class="body-inner">
        <header-component></header-component>
        <section id="page-title" data-bg-parallax="images/checkout_bg.jpg">
            <div class="container">
                <div class="page-title">
                    <h1><strong>Finalizar compra</strong></h1>
                </div>
                <div class="breadcrumb">
                    <ul>
                        <li><router-link to="/">Inicio</router-link>
                        </li>
                        <li><router-link to="/">Clientes</router-link>
                        </li>
                        <li><router-link to="/carrito">Carrito</router-link>
                        </li>
                        <li class="active">
                            <router-link to="/checkout">Finalizar compra</router-link>
                        </li>
                    </ul>
                </div>
            </div>
        </section>
        <!-- SHOP CHECKOUT -->
        <section id="shop-checkout">
            <div class="container">
                <div class="shop-cart" v-if="$store.getters.productos.length">
                    <div class="seperator"><i class="fa fa-credit-card"></i></div>
                    <div class="row">
                        <div class="col-lg-6">
                            <h4 class="upper">Resumen de compra</h4>
                            <div class="table table-sm table-striped table-responsive table table-bordered table-responsive">
                                <table class="table m-b-0">
                                    <thead>
                                        <tr>
                                            <th class="cart-product-thumbnail">Recurso</th>
                                            <th class="cart-product-name">Nivel y grado</th>
                                            <th class="cart-product-name">Cantidad</th>
                                            <th class="cart-product-subtotal">Total</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <template v-for="producto in productos">
                                            <tr :key="producto.id">
                                                <td class="cart-product-thumbnail">
                                                    <div class="cart-product-thumbnail-name">{{ producto.name }}</div>
                                                </td>
                                                <td class="cart-product-description">
                                                    <p> {{ producto.level }}</p>
                                                    <!-- <p>{{ producto.quantity }} X {{ producto.sale_price | currency }}</p> -->
                                                </td>
                                                <td class="cart-product-description">
                                                    <p>{{ producto.quantity }} X {{ producto.sale_price | currency }}</p>
                                                </td>
                                                <td class="cart-product-subtotal">
                                                    <span class="amount">{{ producto.quantity * producto.sale_price | currency }}</span>
                                                </td>
                                            </tr>
                                        </template>
                                    </tbody>
                                </table>
                            </div>
                            <div class="col-lg-12">
                                <div class="table-responsive">
                                    <h4>Orden Total</h4>
                                    <table class="table">
                                        <tbody>
                                            <tr>
                                                <td class="cart-product-name">
                                                    <strong>Total</strong>
                                                </td>
                                                <td class="cart-product-name text-right">
                                                    <span class="amount color lead"><strong>{{ $store.getters.total | currency  }}</strong></span>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                                
                            <div class="col-lg-12">
                                <router-link class="btn btn-secondary icon-left float-right mt-3" to="productos">
                                    <span>Seguir comprando <i class="fa fa-shopping-bag"></i></span>
                                </router-link>
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <div class="row">
                                <div class="col-lg-12">
                                    <h4 class="upper">Seleccionar método de pago</h4>
                                    <div class="list-group">
                                        <!-- <input type="radio" name="radioPagar" value="2" id="radioTransfer" v-model="tipoPago"/>
                                        <label class="list-group-item" for="radioTransfer">Transferencia bancaria</label> -->

                                        <input type="radio" name="radioPagar" value="1" id="radioTarjeta" v-model="tipoPago"/>
                                        <label class="list-group-item" for="radioTarjeta"><img width="90" alt="paypal" src="images/visa-mastercard.png"> <small>Pago con tarjeta</small></label>
                                        <input type="radio" name="radioPagar" value="2" id="radioOxxo" v-model="tipoPago"/>
                                        <label class="list-group-item" for="radioOxxo" v-if="user.tipo_usuario == 5 || user.tipo_usuario == 6"><small>Colocar orden de compra</small></label>
                                    </div>
                                </div>
                                <div class="col-lg-12">
                                    <!-- <div class="alert alert-warning">En estos momentos estamos teniendo problemas con la pasarela de cobros de MasterCard, por favor considéralo antes de realizar el intento de pago</div> -->
                                    <div class='card-wrapper' v-show="tipoPago > 0 && tipoPago == 1"></div>
                                    <vue-form :state="formstate" @submit.prevent="procesarPagoTC" id="formCard" v-show="tipoPago > 0 && tipoPago == 1">
                                        <div class="row">
                                            <div class="col-sm-12 mt-3 ">
                                                <div class="form-group">
                                                    <validate tag="div">
                                                        <input v-model="model.cardNumber" name="cardnumber" id="cardnumber" type="text" required autofocus placeholder="Número de tarjeta" class="form-control" minlength="16" maxlength="22"/>
                                                        <field-messages name="cardnumber" show="$invalid && $submitted" class="text-danger">
                                                            <div slot="required">El número de tarjeta es requerido</div>
                                                            <div slot="minlength">Debe de ingresar de 15 a 18 dígitos</div>
                                                            <div slot="maxlength">Debe de ingresar de 15 a 18 dígitos</div>
                                                        </field-messages>
                                                    </validate>
                                                </div>
                                            </div>
                                            <div class="col-sm-6">
                                                <div class="form-group">
                                                    <validate tag="div">
                                                        <input v-model="model.cardExpiry" name="cardexpiry" id="cardexpiry" type="text" required placeholder="MM/YYYY" class="form-control" length="7"/>
                                                        <field-messages name="cardexpiry" show="$invalid && $submitted" class="text-danger">
                                                            <div slot="required">La fecha de expiración es requerida</div>
                                                        </field-messages>
                                                    </validate>
                                                </div>
                                            </div>
                                            <div class="col-sm-6">
                                                <div class="form-group">
                                                    <validate tag="div">
                                                        <input v-model="model.cardCvc" name="cardcvc" id="cardcvc" type="text" required placeholder="Código de seguridad" class="form-control" />
                                                        <field-messages name="cardcvc" show="$invalid && $submitted" class="text-danger">
                                                            <div slot="required">El código de seguridad es requerido</div>
                                                        </field-messages>
                                                    </validate>
                                                </div>
                                            </div>
                                            <div class="col-sm-6">
                                                <div class="form-group">
                                                    <validate tag="div">
                                                        <input v-model="model.cardName" name="cardname" id="cardname" type="text" required placeholder="Nombre del Titular (Como aparece en la tarjeta)" class="form-control"  />
                                                        <field-messages name="cardname" show="$invalid && $submitted" class="text-danger">
                                                            <div slot="required">El titular de la tarjeta es requerido</div>
                                                        </field-messages>
                                                    </validate>
                                                </div>
                                            </div>
                                            <div class="col-sm-6">
                                                <div class="form-group">
                                                    <validate tag="div">
                                                        <input v-model="model.cardLast" name="cardlast" id="cardlast" type="text" required placeholder="Apellidos del Titular (Como aparece en la tarjeta)" class="form-control"  />
                                                        <field-messages name="cardlast" show="$invalid && $submitted" class="text-danger">
                                                            <div slot="required">El apellido del titular de la tarjeta es requerido</div>
                                                        </field-messages>
                                                    </validate>
                                                </div>
                                            </div>
                                            <!-- <div class="col-sm-12">
                                                <div class="form-group">
                                                    <validate tag="div">
                                                        <input v-model="model.address" name="address" id="address" type="text" required placeholder="Dirección" class="form-control"  />
                                                        <field-messages name="address" show="$invalid && $submitted" class="text-danger">
                                                            <div slot="required">La dirección es requerida</div>
                                                        </field-messages>
                                                    </validate>
                                                </div>
                                            </div>
                                            <div class="col-sm-6">
                                                <div class="form-group">
                                                    <validate tag="div">
                                                        <input v-model="model.state" name="state" id="state" type="text" required placeholder="Estado" class="form-control"  />
                                                        <field-messages name="state" show="$invalid && $submitted" class="text-danger">
                                                            <div slot="required">El estado es requerido</div>
                                                        </field-messages>
                                                    </validate>
                                                </div>
                                            </div>
                                            <div class="col-sm-6">
                                                <div class="form-group">
                                                    <validate tag="div">
                                                        <input v-model="model.city" name="city" id="city" type="text" required placeholder="Ciudad" class="form-control"  />
                                                        <field-messages name="city" show="$invalid && $submitted" class="text-danger">
                                                            <div slot="required">La ciudad es requerida</div>
                                                        </field-messages>
                                                    </validate>
                                                </div>
                                            </div>
                                            <div class="col-sm-6">
                                                <div class="form-group">
                                                    <validate tag="div">
                                                        <input v-model="model.pc" name="pc" id="pc" type="text" required placeholder="Código Postal" class="form-control"  />
                                                        <field-messages name="pc" show="$invalid && $submitted" class="text-danger">
                                                            <div slot="required">El código postal es requerido</div>
                                                        </field-messages>
                                                    </validate>
                                                </div>
                                            </div> -->
                                            <div class="col-sm-6">
                                                <div class="form-group">
                                                    <validate tag="div">
                                                        <input v-model="model.phone" name="phone" id="phone" type="text" required placeholder="Teléfono" class="form-control"  />
                                                        <field-messages name="phone" show="$invalid && $submitted" class="text-danger">
                                                            <div slot="required">El teléfono es requerido</div>
                                                        </field-messages>
                                                    </validate>
                                                </div>
                                            </div>
                                            <div class="col-sm-12">
                                                <input type="submit" value="Procesar pago" class="btn btn-warning btn-block mb-3" v-if="enableButton"/>
                                            </div>
                                        </div>
                                    </vue-form>
                                    <button class="btn icon-left float-right mt-3" href="#" v-if="tipoPago > 0 && tipoPago == 2" @click="procesarPago">
                                        <span>Generar orden de compra</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
/* eslint-disable */
import Card from 'card'
import HeaderComponent from './components/HeaderComponent'
import options from "../validations/validations.js";
Vue.use(VueForm, options);
export default {
  name: 'Checkout',
  components: {
    HeaderComponent
  },
  data() {
        return {
            enableButton: true,
            formstate: {},
            model: {
                cardHolder: '',
                cardNumber: '',
                cardExpiry: '',
                cardCvc: '',
                phone: '',
            },
            total: 0,
            tipoPago: 0,
            productos: [],
            apiConekta: '',
            tipoRegistro: '',
            hostname: '',
            cardType: '',
            Payment: null,
            user: window.user
        }
  },

  methods: {
    
    async calculaTotal(productos) {
        let total = 0
        let subtotal = 0
        productos.forEach((producto) => {
            subtotal = producto.quantity * producto.sale_price
            total = total + subtotal

            if(producto.quantity <= 0){
                this.deleteProduct(producto, this.$store.getters.productos)
            }
        })
        this.$store.commit('setTotal', total)

        await this.updateDataNoForm(`api/update-products-cart`, {productos: productos}, '', '', null, 0, 2, null)
    },

    async procesarPago() {
        // if(this.$store.getters.total <= 10000) {
        await this.saveDataNoForm('api/purchase-orders', { productos: this.productos, tipoPago: this.tipoPago, tipoRegistro: this.tipoRegistro, total: this.$store.getters.total }, '/complete', '', null, 0, 1, null)
        // } else {
        //     Swal.fire({
        //         icon: 'error',
        //         title: 'Oops...',
        //         text: 'Para compras mayores a $10,000 MXN debes de usar el pago con tarjeta.',
        //     })
        // }
    },

    async procesarPagoTC() {
        this.enableButton = false
        if (this.formstate.$invalid) {
            return;
        } else {
            let expiry = this.model.cardExpiry.split('/')
            let number = this.model.cardNumber.replace(/ /g, '')
            // this.model.country = "GT"

            // if(this.model.address == undefined || this.model.address == ''){
                this.model.address = "1295 Charleston Road"
                this.model.city = "Mountain View"
                this.model.state = "CA"
                this.model.country = "US"
                this.model.pc = "94043"
            // }

            let info = {
                "billTo": {
                    "firstName": this.model.cardName,
                    "lastName": this.model.cardLast,
                    "street1": this.model.address,
                    "city": this.model.city,
                    "state": this.model.state,
                    "postalCode": this.model.pc,
                    "country": this.model.country,
                    "phoneNumber": this.model.phone,
                    "email": window.user.email
                },
                "Items": [],
                "currency": window.user.school.currency.key,
                "card": {
                    "accountNumber": number,
                    "expirationMonth": expiry[0],
                    "expirationYear": expiry[1],
                    "cvv": this.model.cardCvc
                },
                captchaToken: '',
            }
            let init = number[0]+number[1]

            if(number[0] == 4){
                info.cardType = '001'
            }
            if((init >= 51 && init <= 55) || number[0] == 2){
                info.cardType = '002'
            }
            if(init >= 34 && init <= 37){
                info.cardType = '003'
            }
            if(init >= 64 && init <= 65){
                info.cardType = '004'
            }

            for(let i = 0; this.productos.length > i; i++){
                info.Items.push({
                    "unitPrice": this.productos[i].sale_price,
                    "quantity": this.productos[i].quantity,
                    "product_id": this.productos[i].product_id,
                    "level_id": this.productos[i].level_id,
                    "order_id": this.productos[i].order_id,
                    "name": this.productos[i].name,
                })
            }

            grecaptcha.execute('6Lf6nd4ZAAAAAF0miQR-oaid9l9VrrUbkeNtSSGW', {action: 'submit'}).then(async (token) => {
                info.captchaToken = token
                // await this.saveDataNoForm('api/orders', info, '/complete', '', null, 0, 1, null)
                await this.saveDataNoForm('api/orders', info, '', '', null, 0, 1, null)
                
                this.$store.commit('clearCarrito')
                this.enableButton = true
            })
        }
    },
},

  async mounted() {
    const scriptPagina = document.createElement('script')
    scriptPagina.src = 'js/functions.js'
    document.body.appendChild(scriptPagina)
   
    if(this.$store.getters.productos.length){
        this.productos = this.$store.getters.productos
        this.calculaTotal(this.$store.getters.productos)
    }
    //card
    var card = new Card({
        form: '#formCard',
        container: '.card-wrapper',
        width: 300, // optional — default 350px
        formatting: true, // optional - default true
        // Strings for translation - optional
        messages: {
            validDate: 'valid\ndate', // optional - default 'valid\nthru'
            monthYear: 'mm/yyyy', // optional - default 'month/year'
        },
        // Default placeholders for rendered fields - optional
        placeholders: {
            number: '•••• •••• •••• ••••',
            name: 'Titular',
            expiry: '••/••',
            cvc: '•••'
        },
        formSelectors: {
            numberInput: 'input[name="cardnumber"]', // optional — default input[name="number"]
            expiryInput: 'input[name="cardexpiry"]', // optional — default input[name="expiry"]
            cvcInput: 'input[name="cardcvc"]', // optional — default input[name="cvc"]
            nameInput: 'input[name="cardname"], input[name="cardlast"]', // optional - defaults input[name="name"]
        },
        masks: {
            cardNumber: '•'
        }
    })

    //ejecutamos el script de banorte
  },
  beforeCreate() {
      //Payment.setEnv("dev");
  },
}
</script>
