<template>
    <div class="body-inner">
        <header-component></header-component>
        <section id="page-title" data-bg-parallax="images/login_bg.jpg">
            <div class="container">
                <div class="page-title">
                    <h1>Portal de compras de ETC Iberoamérica</h1>
                    <h3 class="text-white">Bienvenidos</h3>
                    <span>Por favor inicie sesión<br> o registre una cuenta para ingresar al portal.</span>
                </div>
            </div>
        </section>
        <!-- COUNTERS -->
        <section>
            <div class="container mb-5">
                <div class="row">
                    <div class="col-lg-4 offset-4">
                        <div class="panel ">
                            <div class="panel-body">
                                <h3>Iniciar sesión</h3>
                                <vue-form :state="formstate" @submit.prevent="onSubmit">
                                    <div class="form-group">
                                        <validate tag="div">
                                            <label class="sr-only">Usuario</label>
                                            <input class="form-control" placeholder="Correo electrónico" type="email" name="email" id="email" v-model="model.email" required>
                                            <field-messages name="email" show="$invalid && $submitted" class="text-danger">
                                                <div slot="required">El correo electrónico es requerido</div>
                                                <div slot="email">El correo electrónico no es válido</div>
                                            </field-messages>
                                        </validate>
                                    </div>
                                    <div class="text-left form-group">
                                        <button type="submit" class="btn">Enviar correo de recuperación</button>
                                    </div>
                                </vue-form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <!-- end: SECTION GREY -->
    </div>
</template>

<script>
/* eslint-disable */
import options from "../validations/validations.js";
Vue.use(VueForm, options);
import HeaderComponent from './components/HeaderComponent'
export default {
  name: 'Index',
  components: {
    HeaderComponent
  },
  data() {
      return {
        formstate: {},
        model: {
            email: '',
            password: '',
        },
        hostname: '',
        logo: 'images/logo-etc.png'
      }
  },

  methods: {
    onSubmit() {
        if (this.formstate.$invalid) {
            return;
        } else {
            this.openLoading()
            axios.post(apiKS + 'api/public/password-recovery', this.model)
            .then( response => {
                if(response.data.id == 0 && response.data.token == '') {
                    Swal.close()
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: response.data.message,
                    })
                }else{
                    Swal.fire({
                        icon: 'success',
                        title: 'Éxito',
                        text: 'Se ha enviado un correo, verificar la bandeja de entrada o spam',
                    })
                }
            }).catch(error => {
                this.catchError()
            })
        }
    }
  },
  mounted() {
    const script = document.createElement('script')
    script.src = 'js/functions.js'
    document.body.appendChild(script)
  },
  created() {
      
  },
    beforeMount() {
    }
}
</script>
