<template>
    <!-- Header -->
    <header id="header" data-fullwidth="true" data-responsive-fixed="true" class="submenu-light">
        <div class="header-inner">
            <div class="container">
                <!--Logo-->
                <div id="logo">
                    <router-link to="/">
                        <span class="logo-default"><img src="images/logo-etc.png"></span>
                        <span class="logo-dark"><img src="images/logo-etc-white.png"></span>
                    </router-link>
                </div>
                <!--End: Logo-->
                
                <!--Header Extras-->
                <div id="mainMenu">
                    <div class="container">
                        <nav>
                            <ul>
                                <li v-if="$store.getters.usuario">
                                    <router-link to="/productos">Productos</router-link>
                                </li>
                                <li v-if="$route.path == '/productos' || $route.path.includes('/perfil') || $route.path.includes('/detail') || $route.path.includes('/detalle-producto') || $route.path == '/carrito' || $route.path == '/checkout' || $route.path == '/compras'">
                                    <div class="p-dropdown">
                                        <router-link to="/carrito"><i class="icon-shopping-cart"></i> ( {{ $store.state.carrito.productos.length }} )</router-link>
                                        <ul class="p-dropdown-content">
                                            <li><router-link to="/carrito"><i class="icon-shopping-cart"></i> Ver carrito ( {{ $store.state.carrito.productos.length }} )</router-link></li>
                                        </ul>
                                    </div>
                                </li>
                                <li v-if="$store.getters.usuario">
                                    <router-link to="/compras">Compras</router-link>
                                </li>
                                <li v-if="$route.path == '/productos' || $route.path.includes('/perfil') || $route.path.includes('/detail') || $route.path.includes('/detalle-producto') || $route.path == '/carrito' || $route.path == '/checkout' || $route.path == '/compras'">
                                    <div class="p-dropdown" v-if="$store.getters.usuario">
                                        <router-link to="/perfil">
                                            <i class="icon-user"></i> {{ $store.getters.usuario.nombre }} {{ $store.getters.usuario.apellidos }}
                                        </router-link>
                                        <ul class="p-dropdown-content">
                                            <li><router-link to="/login?logout=1"><i class="icon-log-out"></i> Cerrar sesión</router-link></li>
                                        </ul>
                                    </div>
                                </li>
                                <li v-if="!$store.getters.usuario">
                                    <!-- <router-link to="/login">Ingresar</router-link> -->
                                </li>
                                <!-- <li>
                                    <div>
                                        <a :href="sitioPro"><i class="icon-log-in"></i></a>
                                    </div>
                                </li> -->
                            </ul>
                        </nav>
                    </div>
                </div>
                <!--end: Header Extras-->
                <!--Navigation Resposnive Trigger-->
                <div id="mainMenu-trigger">
                    <a class="lines-button x"><span class="lines"></span></a>
                </div>
                <!--end: Navigation Resposnive Trigger-->
                <!--Navigation-->
                <!-- <div id="mainMenu" class="menu-one-page">
                    <div class="container">
                        <nav v-if="$route.path == '/productos'">
                            <ul>
                                <li><router-link to="/">Inicio</router-link></li>
                            </ul>
                        </nav>
                    </div>
                </div> -->
                <!--end: Navigation-->
            </div>
        </div>
    </header>
</template>

<script>
/* eslint-disable */
export default {
    name: 'HeaderComponent',
    components: {

    },
    data() {
        return {
            sitioPro: '',
            hostname: '',
            logo: 'images/logo-etc.png'
        }
    },

    async mounted() {
        this.hostname = hostname
        if(this.$route.query.logout != 1) {
            const responseUserApi = await this.getUserApi()
            if(responseUserApi == true) {
                this.$store.commit('setUsuario', user)
                localStorage.setItem('user', JSON.stringify(user))
                await this.getProductosCarrito()
                this.$parent.productos = this.$store.getters.productos
            }
        }

        if(!this.$store.getters.usuario && (this.$route.path == '/checkout' || this.$route.path == '/compras')){
            // router.replace('/login')
        }
    },

    beforeCreate() {
        if(!user) {
            if(!headers) {
                if(localStorage.tokenETCSistema) {
                    headers = {
                        'Content-Type': 'application/json',
                        Authorization: localStorage.tokenETCSistema
                    }
                }
            }
        }
    },
}
</script>
