<template lang="pug">
    div.body-inner
        header-component
        section#page-title(data-bg-parallax="images/registro_bg.jpg")
            div.container
                div
                    h1 Portal de compras
                        br
                        template de ETC Iberoamérica
                    h3 Registro de cuenta
        section
            div.container
                div.row
                    div.col-lg-8.center.no-padding
                        vue-form.form-transparent-grey(:state="formstate" @submit.prevent="create()" autocomplete="off")
                                div.row
                                    div.col-lg-12
                                        .row
                                            .col-lg-6
                                                h3 Registre una cuenta nueva
                                                strong Antes de iniciar, descarga el manual de uso de la tienda.
                                                br
                                                a(style="margin:1rem 25%" target="_blank" href="Manual ECommerce ETC Iberoamerica.pdf" class="btn btn-success") Descargar aquí
                                                p Por favor complete los campos solicitados para la creación de su cuenta. Si ya es un usuario registrado por favor diríjase a la sección de
                                                    router-link(to="/login")  Iniciar sesión
                                            .col-lg-6
                                                iframe(src="https://player.vimeo.com/video/864178671?h=d4d9640603" width="640" height="340" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen)
                                    div.col-lg-6.form-group
                                        validate(tag="div")
                                            label(class="sr-only") Nombre del padre o tutor
                                            input#nombres.form-control(type="text" autocomplete="off" v-model="model.nombre" placeholder="* Nombre(s)" required name="nombres")
                                            field-messages.text-danger(name="nombres" show="$invalid && $submitted")
                                                div(slot="required") El nombre es requerido
                                    div.col-lg-6.form-group
                                        validate(tag="div")
                                            label.sr-only Apellido
                                            input#apellidos.form-control(type="text" v-model="model.apellidos" placeholder="* Apellido(s)" required name="apellidos")
                                            field-messages.text-danger(name="apellidos" show="$invalid && $submitted")
                                                div(slot="required") Los apellidos son requeridos
                                    div.col-lg-6.form-group
                                        validate(tag="div")
                                            label.sr-only Contraseña
                                            input#password.form-control(type="password" v-model="model.password" placeholder="* Contraseña" required name="password" minlength="8")
                                            field-messages.text-danger(name="password" show="$invalid && $submitted")
                                                div(slot="required") La contraseña es requerida
                                                div(slot="minlength") La contraseña debe de tener mínimo 8 caracteres
                                    div.col-lg-6.form-group
                                        validate(tag="div")
                                            label.sr-only Confirma Contraseña
                                            input#password.form-control(type="password" v-model="model.password_confirmation" placeholder="* Confirma Contraseña" required name="password_confirmation" minlength="8")
                                    div.col-lg-6.form-group
                                        validate(tag="div")
                                            label.sr-only Correo electrónico
                                            input.form-control#email(type="email" v-model="model.email" placeholder="* Correo electrónico" required name="email")
                                            field-messages.text-danger(name="email" show="$invalid && $submitted")
                                                div(slot="required") El correo electrónico es requerido
                                                div(slot="email") El correo electrónico no es válido
                                    div.col-lg-6.form-group
                                        validate(tag="div")
                                            label.sr-only Teléfono
                                            input.form-control(type="text" v-model="model.telefono" placeholder="Teléfono" name="telefono" id="telefono")
                                    div.col-lg-12.form-group
                                        validate(tag="div")
                                            label.sr-only Institución
                                            v-select#schools(:options="schools" label="name" v-model="model.school_id" name="school" placeholder="Institución" @input="getLevels")
                                div.row.no-padding(v-for="(user, index) in model.user" :key="index")
                                    div.col-lg-6.form-group
                                        label.sr-only Registro de estudiantes
                                        input.form-control(type="text" value="" v-model="user.name" placeholder="Nombre de estudiante")
                                    div.col-lg-5.form-group
                                        label.sr-only Nivel educativo
                                        select.form-control#exampleFormControlSelect1(v-model="user.level" placeholder="Nivel Educativo")
                                            option(value="") Selecciona un nivel educativo
                                            option(v-for="level in levels" :value="level.id") {{ level.name }}
                                    div.col-lg-1.form-group
                                        a(@click.prevent="removeStudent(index)" v-if="index > 0" href="#")
                                            i.fa.fa-trash.text-danger
                                .row.align-right
                                    .col-lg-8
                                    .col-lg-4
                                        a.btn-link(@click.prevent="addUser" v-if="model.user.length < 10" href="#")
                                            i.fa.fa-plus
                                            template  Agregar otro estudiante
                                .row
                                    div.seperator
                                        i.fa.fa-file-invoice
                                    div.col-lg-12
                                        h5 Información de facturación
                                        small Por disposición oficial para la emisión de facturas con montos superiores a Q2,500 se requerirá el NIT, DIP o número de pasaoporte en caso de ser una entidad extranjera.
                                        br
                                        small Por favor introduce el NIT sin guiones (-)
                                    div.col-lg-12.form-group.row
                                        .form-check.col-lg-1
                                        .form-check.col-lg-3
                                            input.form-check-input(type="radio" value="nit" v-model="model.nit_type" name="nit_type")
                                            label.form-check-label NIT
                                        .form-check.col-lg-4
                                            input.form-check-input(type="radio" value="dpi" v-model="model.nit_type" name="nit_type")
                                            label.form-check-label DPI
                                        .form-check.col-lg-4
                                            input.form-check-input(type="radio" value="passport" v-model="model.nit_type" name="nit_type")
                                            label.form-check-label Pasaporte
                                    div.col-lg-4.form-group
                                        validate(tag="div")
                                            label.sr-only Número de identificación
                                            input.form-control(type="text" value="" v-model="model.nit" placeholder="NIT" name="nit" required  @change.prevent="checkNIT")
                                            field-messages.text-danger(name="nit" show="$invalid && $submitted")
                                                div(slot="required") El número de identificación es requerido
                                    div.col-lg-4.form-group
                                        validate(tag="div")
                                            label.sr-only Nombre para facturación
                                            input.form-control(type="text" value="" v-model="model.fiscal_name" name="fiscal_name" placeholder="Nombre para facturación" required)
                                            field-messages.text-danger(name="fiscal_name" show="$invalid && $submitted")
                                                div(slot="required") El nombre para facturación es requerido
                                    div.col-lg-4.form-group
                                        validate(tag="div")
                                            label.sr-only Email
                                            input.form-control(type="text" value="" v-model="model.fiscal_email" placeholder="Email" name="fiscal_email" required)
                                            field-messages.text-danger(name="fiscal_email" show="$invalid && $submitted")
                                                div(slot="required") El Email es requerido
                                    div.seperator
                                    div.col-lg-6.form-group
                                        router-link.btn-link(to="/login") Ya tengo cuenta Ingresar
                                    div.col-lg-6.form-group
                                        button.btn.float-right(type="submit") Registrar cuenta
                                    
</template>
<script>
/* eslint-disable */
import options from "../validations/validations.js";
import headerComponent from '../pages/components/HeaderComponent'
Vue.use(VueForm, options);
export default {
    name: 'Registro',
    components: {
        headerComponent
    },
    data() {
        return {
            countries: [],
            cities: [],
            levels: [],
            schools: [],
            states: [],
            formstate: {},
            model: {
                nombre: '',
                apellidos: '',
                email: '',
                nit: '',
                password: '',
                id_pais: '',
                id_idioma: '',
                id_estado: '',
                id_ciudad: '',
                id_zona_horaria: '',
                fecha_nacimiento: '',
                tipo_usuario: {id: '4'},
                telefono: '',
                user: []
            },
            hostname: '',
            user: {},
            logo: 'images/logo-etc.png'
        }
    },

    methods: {
        addUser(){
            if(this.model.user.length < 10){
                this.model.user.push({ level: '' })
            }
        },
        async create() {
            let flag = true
            for(let i = 0; i < this.model.user.length; i++){
                if(this.model.user[i].name == '' || this.model.user[i].name == undefined || this.model.user[i].level == undefined || this.model.user[i].level == ''){
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'No se tienen todos los datos del estudiante',
                    })
                    flag = false
                }
            }
            if(this.model.user.length == 0){
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'No se ha registrado ningún estudiante',
                })
                return;
            }else{
                if(flag){
                    if (this.formstate.$invalid) {
                        return;
                    } else {
                        axios.post(apiKS + 'api/public/users', this.model)
                            .then( response => {
                                if(response.data.id == 0 && response.data.token == '') {
                                    Swal.close()
                                    Swal.fire({
                                        icon: 'error',
                                        title: 'Oops...',
                                        text: response.data.message,
                                    })
                                }
                                if(response.data.status && response.data.token) {
                                    Swal.close()
                                    localStorage.tokenETCSistema = 'Bearer ' + response.data.token
                                    headers = {
                                        'Content-Type': 'application/json',
                                        Authorization: localStorage.tokenETCSistema
                                    }
                                    axios.get(apiKS + 'api/user', { headers })
                                        .then( response => {
                                            localStorage.userETC = response.data.userEncrypted
                                            axios.post(apiKS + 'api/userdecrypt', {usuario: localStorage.userETC}, { headers })
                                                .then( response => {
                                                    user = JSON.parse(response.data.user)
                                                    this.$store.commit('setUsuario', user)
                                                    // axios.post(apiKS + 'api/crea-carrito', this.$store.state.carrito, { headers })
                                                    // .then( response => {
                                                        router.replace('/productos')
                                                    // } )
                                                    // .catch(error => {
                                                    //     this.catchError(error)
                                                    // })
                                                })
                                        })
                                }
                            })
                    }
                }
            }
        },
        checkNIT(){
            if(this.model.nit != 'CF' && this.model.nit_type == 'nit'){
                this.openLoading()
                axios.get(apiKS + 'api/public/check-nit', { params: { nit: this.model.nit }}).then(response => {
                    if(response.data.Result == 'true'){
                        Swal.close()
                        this.model.fiscal_name = response.data.nombre
                        this.$forceUpdate()
                    }else{
                        Swal.close()
                        Swal.fire({
                            icon: 'error',
                            title: 'Oops...',
                            text: response.data.error,
                        })
                    }
                })
            }
        },
        async getCities(){
            await this.getCitiesPublic(this.model.state.id)
        },
        async getLevels(){
            await this.getLevelSchool(this.model.school_id.id)
            this.model.user = []
            this.addUser()
        },
        async getStates(){
            await this.getStatesPublic(this.model.country.id)
        },
        removeStudent(index){
            this.model.user.splice(index, 1)
        }
    },
    async mounted() {
        const script = document.createElement('script')
        script.src = 'js/functions.js'
        document.body.appendChild(script)

        this.hostname = hostname
        await this.getCountriesPublic()
        await this.getSchools()
    },
}
</script>
