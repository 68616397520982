<template>
    <div class="body-inner">
        <header-component></header-component>
        <section id="page-title" data-bg-parallax="images/checkout_bg.jpg">
            <div class="container">
                <div class="page-title">
                    <h1><strong>Compras realizadas</strong></h1>
                </div>
                <div class="breadcrumb">
                    <ul>
                        <li><router-link to="/">Inicio</router-link>
                        </li>
                        <li><router-link to="/">Clientes</router-link>
                        </li>
                        <li><router-link to="/carrito">Carrito</router-link>
                        </li>
                        <li class="active">
                            <router-link to="/compras">Compras realizadas</router-link>
                        </li>
                    </ul>
                </div>
            </div>
        </section>
        <!-- DETALLE DE PRODUCTO -->
        <section id="product-page" class="product-page p-b-0" v-if="$store.getters.usuario">
            <div class="container">
                <div class="product">
                    <div class="row m-b-40">
                        <div class="col-lg-2">
                            <div class="product-image">
                                
                            </div>
                        </div>
                        <div class="col-lg-10">
                            <div class="product-description">
                                <div class="product-category"></div>
                                <div class="product-title">
                                    <h3>
                                        <a href="#">Tus compras realizadas</a>
                                    </h3>
                                </div>
                                <div class="product-price"></div>
                                <p>Aquí podras consultar las compras que has realizado con nosotros</p>
                                <div class="seperator m-t-20 m-b-10"></div>
                                <div class="row">
                                    <div class="col-md-12" v-if="$store.getters.usuario.distribuitor">
                                        <div class="table table-sm table-striped table-responsive">
                                            <table class="table">
                                                <thead>
                                                    <tr>
                                                        <th colspan="4" class="cart-product-thumbnail">Ordenes de compra</th>
                                                    </tr>
                                                    <tr>
                                                        <th class="cart-product-thumbnail"># Orden</th>
                                                        <th class="cart-product-price">Total</th>
                                                        <th class="cart-product-thumbnail">Estatus</th>
                                                        <th class="cart-product-thumbnail">Acciones</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr v-for="compra in compras" :key="compra.id">
                                                        <td class="cart-product-thumbnail">
                                                            {{ compra.key }}
                                                        </td>
                                                        <td class="cart-product-thumbnail">
                                                            {{ compra.total }}
                                                        </td>
                                                        <td class="cart-product-thumbnail">
                                                            {{ compra.status == 0 ? 'Pendiente de pago' : compra.status == 1 ? 'Pendiente de pago' : compra.status == 2 ? 'Pagado' : compra.status == 'Pagada' ? 'Pagada' : compra.status == 'Anulada' ? 'Anulada' : compra.status == 'Pendiente de pago' ? 'Pendiente de pago' : 'Desconocido'   }}
                                                        </td>
                                                        <td>
                                                            <router-link :to="`/detail/${compra.id}?po=1`">
                                                                <i class="fa fa-search"></i> Ver detalle
                                                            </router-link>
                                                            <a href="#" @click.prevent="setOrder(compra)" v-if="compra.status == 'Anulada'">
                                                                <i class="fa fa-file-invoice-dollar"></i> Pagar orden
                                                            </a>
                                                            <a href="#" @click.prevent="downloadCodes(compra)" v-if="compra.distributor_id > 0 && compra.file_url_codes != null">
                                                                <i class="fa fa-download"></i> Descargar códigos
                                                            </a>
                                                            <a href="#" @click.prevent="uploadPayment(compra)" v-if="compra.distributor_id > 0 && compra.payment_document == null">
                                                                <i class="fa fa-upload"></i> Subir comprobante de pago
                                                            </a>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>

                                    <div class="col-md-12">
                                        <div class="table table-sm table-striped table-responsive">
                                            <table class="table">
                                                <thead>
                                                    <tr>
                                                        <th colspan="5" class="cart-product-thumbnail">Compras en línea</th>
                                                    </tr>
                                                    <tr>
                                                        <th class="cart-product-thumbnail"># Orden</th>
                                                        <th class="cart-product-thumbnail">Fecha</th>
                                                        <th class="cart-product-price">Total</th>
                                                        <th class="cart-product-thumbnail">Estatus</th>
                                                        <th class="cart-product-thumbnail">Acciones</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr v-for="compra in onlinePurchases" :key="compra.id">
                                                        <td class="cart-product-thumbnail">
                                                            {{ compra.key }}
                                                        </td>
                                                        <td class="cart-product-thumbnail">
                                                            {{ compra.date }}
                                                        </td>
                                                        <td class="cart-product-thumbnail">
                                                            {{ compra.total }}
                                                        </td>
                                                        <td class="cart-product-thumbnail">
                                                            {{ compra.status == 0 ? 'Pendiente de pago' : compra.status == 1 ? 'Pendiente de pago' : compra.status == 2 ? 'Pagado' : compra.status == 'Pagada' ? 'Pagada' : compra.status == 'Anulada' ? 'Anulada' : compra.status == 'Pendiente de pago' ? 'Pendiente de pago' : 'Desconocido'   }}
                                                        </td>
                                                        <td>
                                                            <router-link :to="`/detail/${compra.id}?po=0`">
                                                                <i class="fa fa-search"></i> Ver detalle
                                                            </router-link>
                                                            <a href="#" @click.prevent="setOrder(compra)" v-if="compra.status == 'Anulada'">
                                                                <i class="fa fa-file-invoice-dollar"></i> Pagar orden
                                                            </a>
                                                            <a href="#" @click.prevent="downloadCodes(compra)" v-if="compra.distributor_id > 0 && compra.file_url_codes != null">
                                                                <i class="fa fa-download"></i> Descargar códigos
                                                            </a>
                                                            <a href="#" @click.prevent="uploadPayment(compra)" v-if="compra.distributor_id > 0 && compra.payment_document == null">
                                                                <i class="fa fa-upload"></i> Subir comprobante de pago
                                                            </a>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <div class="modal fade" id="modalPayment" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel">Cargar comprobante de pago</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <vue-dropzone ref="mydropzone" id="dropzone" :options="dropzoneOptions" v-if="showDropzone"></vue-dropzone>
                </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
/* eslint-disable */
import HeaderComponent from './components/HeaderComponent'
import vue2Dropzone from 'vue2-dropzone'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'
Vue.component('vueDropzone', vue2Dropzone)
export default {
    name: 'Compras',
    components: {
    HeaderComponent
    },
    data() {
        return {
            compras: [],
            onlinePurchases: [],
            showDropzone: false,
            dropzoneOptions: {
                url: 'api/load-codes?order_id=',
                thumbnailWidth: 50,
                maxFileSize: 10,
                paramName: 'file',
                timeout: 600000,
                acceptedFiles: '.pdf',
                headers: {
                    Authorization: localStorage.tokenETCSistema
                },
                dictDefaultMessage: "Clic aquí para subir comprobante de pago",
                accept: (file, done) => {
                    done()
                },
                success: ( file, response ) => {
                    if(response.status == true) {
                        Swal.fire({
                            title: "Exito",
                            text: response.message,
                            icon: "success",
                            onClose: async () => {
                                $("#modalPayment").modal('hide')
                                await this.getCompras()
                                Swal.close()
                            }
                        })
                    }
                }
            },
        }
    },

    methods: {
        async setOrder(compra){
            
            Swal.fire({
                    title: 'Aviso',
                    text: "¿Está seguro de realizar esta acción? Se vaciará el carrito actual para llenarlo con esta orden",
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Sí, realizarla!',
                    cancelButtonText: 'Cancelar'
                })
                .then(async (result) => {
                    if(result.isConfirmed){
                        this.openLoading('Espere por favor...')
                            headers = {
                                'Content-Type': 'application/json',
                                Authorization: localStorage.tokenETCSistema
                            }
                            await axios.get(apiKS + "api/orders/"+ compra.id, { headers: headers })
                                .then( response => {
                                    let order = response.data

                                    this.$store.commit('clearCarrito')
                                    axios.post(apiKS + 'api/clear-carrito', {} ,{ headers: headers })

                                    order.products.forEach(product => {
                                        let producto = {
                                            product_id: product.product_id,
                                            name: product.license.name,
                                            level: product.level.name,
                                            level_id: product.level_id,
                                            order_id: product.order_id,
                                            quantity: 1,
                                            sale_price: user.tipo_usuario == 6 ? product.license.dist_price : product.license.sale_price
                                        }

                                        this.$store.commit('addProduct', producto)

                                        //verificamos si el usuario está logueado
                                        if(this.$store.getters.usuario) {
                                            axios.post(apiKS + 'api/carrito', producto,  { headers: headers })
                                        }
                                    })
                                })
                        
                            Swal.close()
                            router.push({ name: "Checkout" })
                    }
                })
        },
        uploadPayment(purchaseOrder) {
            this.dropzoneOptions.url = `${apiKS}api/load-payment?order_id=${purchaseOrder.id}`
            this.showDropzone = true
            $("#modalPayment").modal('show')
        },
        async getComprasOnline() {
            let url = ''
            url = `api/orders?user_id=${this.$store.getters.usuario.id}`

            await axios.get(apiKS + url, { headers: headers })
                .then( ({ data }) => (this.onlinePurchases = data.data))
                .catch( error => {
                    this.catchError(error)
                })
        },
    },

    async mounted() {
        this.openLoading()
        const script = document.createElement('script')
        script.src = 'js/functions.js'
        document.body.appendChild(script)

        setTimeout(async () => {
            await this.getCompras()
            await this.getComprasOnline()
            Swal.close()

        }, 1000)
        
    },
}
</script>
