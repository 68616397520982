<template>
    <div class="body-inner">
        <header-component></header-component>
        <section id="page-title" data-bg-parallax="images/login_bg.jpg">
            <div class="container">
                <div class="page-title">
                    <h1>Portal de compras de ETC Iberoamérica</h1>
                    <h3 class="text-white">Bienvenidos</h3>
                    <span>Por favor inicie sesión<br> o registre una cuenta para ingresar al portal.</span>
                </div>
            </div>
        </section>
        <!-- COUNTERS -->
        <section>
            <div class="WordSection1 container">
                <div align=center>
                    <table class=MsoNormalTable border=0 cellspacing=0 cellpadding=0 width="100%"
                    style='width:100.0%;border-collapse:collapse;mso-yfti-tbllook:1184;mso-padding-alt:
                    0cm 0cm 0cm 0cm'>
                    <tr style='mso-yfti-irow:0;mso-yfti-firstrow:yes'>
                    <td style='padding:0cm 0cm 0cm 0cm'>
                    <p class=MsoNormal align=center style='margin-bottom:0cm;margin-bottom:.0001pt;
                    text-align:center;line-height:normal'><b><span lang=EN-US style='font-size:
                    15.0pt;font-family:"Arial",sans-serif;mso-fareast-font-family:"Times New Roman";
                    color:black;mso-font-kerning:0pt;mso-ligatures:none'>AVISO DE PRIVACIDAD</span></b><span
                    lang=EN-US style='font-size:12.0pt;font-family:"Times New Roman",serif;
                    mso-fareast-font-family:"Times New Roman";mso-font-kerning:0pt;mso-ligatures:
                    none'><o:p></o:p></span></p>
                    </td>
                    </tr>
                    <tr style='mso-yfti-irow:1;mso-yfti-lastrow:yes'>
                    <td style='padding:0cm 0cm 0cm 0cm'>
                    <p class=MsoNormal style='margin-bottom:0cm;margin-bottom:.0001pt;line-height:
                    normal'><span lang=EN-US style='font-size:15.0pt;font-family:"Arial",sans-serif;
                    mso-fareast-font-family:"Times New Roman";color:black;mso-font-kerning:0pt;
                    mso-ligatures:none'>&nbsp;</span><span lang=EN-US style='font-size:12.0pt;
                    font-family:"Times New Roman",serif;mso-fareast-font-family:"Times New Roman";
                    mso-font-kerning:0pt;mso-ligatures:none'><o:p></o:p></span></p>
                    </td>
                    </tr>
                    </table>

                    </div>

                    <p class=MsoNormal style='margin-bottom:0cm;margin-bottom:.0001pt;line-height:
                    normal'><span lang=EN-US style='font-size:12.0pt;font-family:"Times New Roman",serif;
                    mso-fareast-font-family:"Times New Roman";display:none;mso-hide:all;mso-font-kerning:
                    0pt;mso-ligatures:none'><o:p>&nbsp;</o:p></span></p>
                    <table class=MsoNormalTable border=0 cellspacing=0 cellpadding=0 width="100%" style='width:100.0%;border-collapse:collapse;mso-yfti-tbllook:1184;mso-padding-alt:0cm 0cm 0cm 0cm'>
                    <tr style='mso-yfti-irow:0;mso-yfti-firstrow:yes'>
                    <td style='padding:0cm 0cm 0cm 0cm'>
                    <p class=MsoNormal style='margin-bottom:0cm;margin-bottom:.0001pt;line-height:
                    normal'><span lang=ES-GT style='font-family:"Arial",sans-serif;mso-fareast-font-family:
                    "Times New Roman";color:black;mso-font-kerning:0pt;mso-ligatures:none;
                    mso-ansi-language:ES-GT'>EDUCACIÓN PARA EL FUTURO, S.A., mejor conocido como
                    ETC IBEROAMÉRICA, con domicilio ubicado en 15 avenida 5-65, zona 13, código
                    postal 01013, Ciudad de Guatemala, Guatemala, y portal de internet
                    www.etciberoamerica.com.gt, es el responsable del uso y protección de sus
                    datos personales, y al respecto le informamos lo siguiente:</span><span
                    lang=ES-GT style='font-size:12.0pt;font-family:"Times New Roman",serif;
                    mso-fareast-font-family:"Times New Roman";mso-font-kerning:0pt;mso-ligatures:
                    none;mso-ansi-language:ES-GT'><o:p></o:p></span></p>
                    </td>
                    </tr>
                    <tr style='mso-yfti-irow:1'>
                    <td style='padding:0cm 0cm 0cm 0cm'>
                    <p class=MsoNormal style='margin-bottom:0cm;margin-bottom:.0001pt;line-height:
                    normal'><span lang=ES-GT style='font-family:"Arial",sans-serif;mso-fareast-font-family:
                    "Times New Roman";color:black;mso-font-kerning:0pt;mso-ligatures:none;
                    mso-ansi-language:ES-GT'>&nbsp;</span><span lang=ES-GT style='font-size:12.0pt;
                    font-family:"Times New Roman",serif;mso-fareast-font-family:"Times New Roman";
                    mso-font-kerning:0pt;mso-ligatures:none;mso-ansi-language:ES-GT'><o:p></o:p></span></p>
                    </td>
                    </tr>
                    <tr style='mso-yfti-irow:2'>
                    <td style='padding:0cm 0cm 0cm 0cm'>
                    <p class=MsoNormal style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
                    margin-left:36.0pt;margin-bottom:.0001pt;text-indent:-18.0pt;line-height:
                    normal'><b><span lang=ES-GT style='font-family:"Arial",sans-serif;mso-fareast-font-family:
                    "Times New Roman";color:black;mso-font-kerning:0pt;mso-ligatures:none;
                    mso-ansi-language:ES-GT'>A.</span></b><b><span lang=ES-GT style='font-size:
                    7.0pt;font-family:"Times New Roman",serif;mso-fareast-font-family:"Times New Roman";
                    color:black;mso-font-kerning:0pt;mso-ligatures:none;mso-ansi-language:ES-GT'>&nbsp;&nbsp;&nbsp;&nbsp;</span></b><b><span
                    lang=ES-GT style='font-family:"Arial",sans-serif;mso-fareast-font-family:
                    "Times New Roman";color:black;mso-font-kerning:0pt;mso-ligatures:none;
                    mso-ansi-language:ES-GT'>¿En qué utilizaremos sus datos personales?</span></b><span
                    lang=ES-GT style='font-size:12.0pt;font-family:"Times New Roman",serif;
                    mso-fareast-font-family:"Times New Roman";mso-font-kerning:0pt;mso-ligatures:
                    none;mso-ansi-language:ES-GT'><o:p></o:p></span></p>
                    </td>
                    </tr>
                    <tr style='mso-yfti-irow:3'>
                    <td style='padding:0cm 0cm 0cm 0cm'>
                    <p class=MsoNormal style='margin-bottom:0cm;margin-bottom:.0001pt;line-height:
                    normal'><span lang=ES-GT style='font-family:"Arial",sans-serif;mso-fareast-font-family:
                    "Times New Roman";color:black;mso-font-kerning:0pt;mso-ligatures:none;
                    mso-ansi-language:ES-GT'>&nbsp;</span><span lang=ES-GT style='font-size:12.0pt;
                    font-family:"Times New Roman",serif;mso-fareast-font-family:"Times New Roman";
                    mso-font-kerning:0pt;mso-ligatures:none;mso-ansi-language:ES-GT'><o:p></o:p></span></p>
                    </td>
                    </tr>
                    <tr style='mso-yfti-irow:4'>
                    <td style='padding:0cm 0cm 0cm 0cm'>
                    <p class=MsoNormal style='margin-bottom:0cm;margin-bottom:.0001pt;line-height:
                    normal'><span lang=ES-GT style='font-family:"Arial",sans-serif;mso-fareast-font-family:
                    "Times New Roman";color:black;mso-font-kerning:0pt;mso-ligatures:none;
                    mso-ansi-language:ES-GT'>Los datos personales que recabamos de usted, los
                    utilizaremos para las siguientes finalidades que son necesarias para el
                    servicio que solicita:<o:p></o:p></span></p>
                    </td>
                    </tr>
                    <tr style='mso-yfti-irow:5'>
                    <td style='padding:0cm 0cm 0cm 0cm'>
                    <ul style='margin-top:0cm' type=disc>
                    <li class=MsoNormal style='color:black;margin-bottom:0cm;margin-bottom:.0001pt;
                        line-height:normal;mso-list:l1 level1 lfo1;tab-stops:list 36.0pt'><b><span
                        lang=ES-GT style='font-family:"Arial",sans-serif;mso-fareast-font-family:
                        "Times New Roman";mso-font-kerning:0pt;mso-ligatures:none;mso-ansi-language:
                        ES-GT'>Suministro de artículos o servicios solicitados:</span></b><span
                        lang=ES-GT style='font-family:"Arial",sans-serif;mso-fareast-font-family:
                        "Times New Roman";mso-font-kerning:0pt;mso-ligatures:none;mso-ansi-language:
                        ES-GT'>&nbsp;Si solicita artículos o servicios de ETC Iberoamérica, ETC
                        Iberoamérica, utilizará los Datos Personales que introduzca en el pedido
                        o en el formulario de registro (generalmente un subconjunto de) su
                        nombre, dirección (de correo electrónico), número de teléfono, nombre y
                        dirección de empresa, su cargo y rol y, si se debe realizar algún pago a
                        ETC Iberoamérica, el número de la tarjeta de crédito o los datos bancarios)
                        únicamente para procesar su pedido, o para suministrar los artículos o
                        servicios solicitados. Esto puede incluir dar los pasos necesarios antes
                        de formalizar el contrato, responder a sus consultas relacionadas con el
                        mismo, proporcionarle información sobre el envío y la facturación, y
                        procesar o proporcionar opiniones de clientes y soporte. Asimismo,
                        también puede incluir datos de conversaciones que mantenga a través del
                        chat en ETC Iberoamérica u otras páginas Web de ETC Iberoamérica
                        locales, formularios de contacto, correos electrónicos o teléfono. En
                        esta Declaración de Privacidad, &quot;artículos y servicios&quot;
                        incluye (acceso a) servicios Web, ofertas, concursos, sorteos, otro
                        contenido, boletines informativos no relacionados con campañas de marketing,
                        documentos técnicos, tutoriales, formaciones y eventos de ETC
                        Iberoamérica.</span><span lang=ES-GT style='font-size:12.0pt;font-family:
                        "Times New Roman",serif;mso-fareast-font-family:"Times New Roman";
                        mso-font-kerning:0pt;mso-ligatures:none;mso-ansi-language:ES-GT'><o:p></o:p></span></li>
                    </ul>
                    <p class=MsoNormal style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
                    margin-left:36.0pt;margin-bottom:.0001pt;line-height:normal'><span
                    lang=ES-GT style='font-size:12.0pt;font-family:"Times New Roman",serif;
                    mso-fareast-font-family:"Times New Roman";color:black;mso-font-kerning:0pt;
                    mso-ligatures:none;mso-ansi-language:ES-GT'><o:p>&nbsp;</o:p></span></p>
                    <p class=MsoNormal style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
                    margin-left:36.0pt;margin-bottom:.0001pt;line-height:normal'><span
                    lang=ES-GT style='font-family:"Arial",sans-serif;mso-fareast-font-family:
                    "Times New Roman";color:black;mso-font-kerning:0pt;mso-ligatures:none;
                    mso-ansi-language:ES-GT'>Si participa en tutoriales o formaciones
                    proporcionados por ETC Iberoamérica, ETC Iberoamérica puede realizar un
                    seguimiento de su progreso de aprendizaje a fin de poner esta información a
                    su disposición.&nbsp;<a name=ip></a>Además, nos comunicamos de manera regular
                    por correo electrónico con aquellos usuarios que se suscriban a nuestros
                    servicios. También es posible que nos comuniquemos por teléfono para
                    solucionar reclamaciones de cliente o investigar transacciones sospechosas.
                    Podemos utilizar su dirección de correo electrónico para confirmar la
                    apertura de una cuenta y para enviarle notificaciones de pagos, información
                    sobre modificaciones en nuestros productos y servicios y avisos y otras publicaciones
                    requeridas por la ley. Normalmente, los usuarios no pueden rechazar estas
                    comunicaciones, ya que no se realizan con fines de publicidad, sino que son
                    necesarias para la relación comercial. Respecto a los tipos de comunicación
                    con fines de publicidad (es decir, correos electrónicos y llamadas
                    telefónicas), ETC Iberoamérica (i) solo le proporcionará dicha información,
                    si así lo exige la ley, después de que haya concedido su consentimiento y
                    (ii) le ofrecerá la oportunidad de optar por la anulación, si no desea
                    recibir este tipo de comunicación con fines de publicidad. Puede anular su
                    suscripción en cualquier momento en&nbsp;</span><span lang=EN-US><a
                    href="http://www.etciberoamerica.com.gt"><span lang=ES-GT style='font-family:
                    "Arial",sans-serif;mso-fareast-font-family:"Times New Roman";mso-font-kerning:
                    0pt;mso-ligatures:none;mso-ansi-language:ES-GT'>www.etciberoamerica.com.gt</span></a></span><span
                    lang=ES-GT style='font-family:"Arial",sans-serif;mso-fareast-font-family:
                    "Times New Roman";color:black;mso-font-kerning:0pt;mso-ligatures:none;
                    mso-ansi-language:ES-GT'><o:p></o:p></span></p>
                    <p class=MsoNormal style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
                    margin-left:36.0pt;margin-bottom:.0001pt;line-height:normal'><span
                    lang=ES-GT style='font-size:12.0pt;font-family:"Times New Roman",serif;
                    mso-fareast-font-family:"Times New Roman";mso-font-kerning:0pt;mso-ligatures:
                    none;mso-ansi-language:ES-GT'><o:p>&nbsp;</o:p></span></p>
                    <ul style='margin-top:0cm' type=disc>
                    <li class=MsoNormal style='color:black;margin-bottom:0cm;margin-bottom:.0001pt;
                        line-height:normal;mso-list:l0 level1 lfo2;tab-stops:list 36.0pt'><b><span
                        lang=ES-GT style='font-family:"Arial",sans-serif;mso-fareast-font-family:
                        "Times New Roman";mso-font-kerning:0pt;mso-ligatures:none;mso-ansi-language:
                        ES-GT'>Garantía de Cumplimiento:</span></b><span lang=ES-GT
                        style='font-family:"Arial",sans-serif;mso-fareast-font-family:"Times New Roman";
                        color:#333333;mso-font-kerning:0pt;mso-ligatures:none;mso-ansi-language:
                        ES-GT'>&nbsp;</span><span lang=ES-GT style='font-family:"Arial",sans-serif;
                        mso-fareast-font-family:"Times New Roman";mso-font-kerning:0pt;
                        mso-ligatures:none;mso-ansi-language:ES-GT'>Tanto ETC Iberoamérica, como
                        sus productos, tecnologías y servicios están sujetos a las leyes de
                        exportación de varios países, incluidos, sin limitarse a ellos, los
                        países de España, Brasil, Centro América y Colombia, Portugal y
                        República Dominicana. Reconoce estar informado de que, de acuerdo con
                        las leyes de exportación, las sanciones comerciales y los bloqueos
                        aplicables emitidos por estos países, ETC Iberoamérica está obligada a
                        tomar medidas para evitar que las entidades, organizaciones, y partes
                        que se mencionen en las listas de partes sancionadas emitidas por el
                        gobierno, accedan a determinados productos, tecnologías y servicios a
                        través de los sitios Web de ETC Iberoamérica u otros canales de entrega
                        controlados por ETC Iberoamérica. Esto puede incluir (i) verificaciones
                        automáticas de cualquier dato de registro de usuario, de conformidad con
                        lo especificado en el presente, así como cualquier otra información que
                        un usuario proporcione acerca de su identidad en las listas de partes
                        sancionadas aplicables; (ii) la repetición periódica de dichas
                        verificaciones cada vez que se actualice una lista de partes sancionadas
                        o cuando un usuario actualice su información; (iii) el bloqueo del
                        acceso a los servicios y sistemas de ETC Iberoamérica en caso de posible
                        coincidencia; y (iv) el contacto con un usuario para confirmar su
                        identidad en caso de posible coincidencia.</span><span lang=ES-GT
                        style='font-size:12.0pt;font-family:"Times New Roman",serif;mso-fareast-font-family:
                        "Times New Roman";mso-font-kerning:0pt;mso-ligatures:none;mso-ansi-language:
                        ES-GT'><o:p></o:p></span></li>
                    </ul>
                    <p class=MsoNormal style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
                    margin-left:36.0pt;margin-bottom:.0001pt;line-height:normal'><span
                    lang=ES-GT style='font-family:"Arial",sans-serif;mso-fareast-font-family:
                    "Times New Roman";color:black;mso-font-kerning:0pt;mso-ligatures:none;
                    mso-ansi-language:ES-GT'>Además, reconoce que cualquier información necesaria
                    para realizar un seguimiento de sus elecciones, en relación con el
                    tratamiento o el uso de sus Datos Personales, o la recepción de materiales de
                    marketing (siempre en función del país en el que opera la empresa relevante
                    de ETC Iberoamérica, independientemente de si ha otorgado su consentimiento
                    expreso o si ha optado por anular la recepción de materiales de marketing),
                    se almacenará y se podrá intercambiar entre miembros de ETC Iberoamérica, a
                    fin de garantizar el cumplimiento.</span><span lang=ES-GT style='font-size:
                    12.0pt;font-family:"Times New Roman",serif;mso-fareast-font-family:"Times New Roman";
                    mso-font-kerning:0pt;mso-ligatures:none;mso-ansi-language:ES-GT'><o:p></o:p></span></p>
                    <ul style='margin-top:0cm' type=disc>
                    <li class=MsoNormal style='color:black;margin-bottom:0cm;margin-bottom:.0001pt;
                        line-height:normal;mso-list:l4 level1 lfo3;tab-stops:list 36.0pt'><b><span
                        lang=ES-GT style='font-family:"Arial",sans-serif;mso-fareast-font-family:
                        "Times New Roman";mso-font-kerning:0pt;mso-ligatures:none;mso-ansi-language:
                        ES-GT'>Interés Legítimo de ETC IBEROAMERICA</span></b><span lang=ES-GT
                        style='font-family:"Arial",sans-serif;mso-fareast-font-family:"Times New Roman";
                        mso-font-kerning:0pt;mso-ligatures:none;mso-ansi-language:ES-GT'>: Cada
                        uno de los casos prácticos que se enumeran a continuación se considera
                        un interés legítimo de ETC Iberoamérica, para tratar o utilizar sus
                        Datos Personales. Si no está de acuerdo con este enfoque, puede oponerse
                        al tratamiento, o al uso de sus Datos Personales, por parte de ETC
                        Iberoamérica según se especifica a continuación.</span><span lang=ES-GT
                        style='font-size:12.0pt;font-family:"Times New Roman",serif;mso-fareast-font-family:
                        "Times New Roman";mso-font-kerning:0pt;mso-ligatures:none;mso-ansi-language:
                        ES-GT'><o:p></o:p></span></li>
                    </ul>
                    <p class=MsoNormal style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
                    margin-left:36.0pt;margin-bottom:.0001pt;line-height:normal'><span
                    lang=ES-GT style='font-family:"Arial",sans-serif;mso-fareast-font-family:
                    "Times New Roman";color:black;mso-font-kerning:0pt;mso-ligatures:none;
                    mso-ansi-language:ES-GT'>Cuestionarios y encuestas. ETC Iberoamérica puede
                    invitarle a participar en cuestionarios y encuestas. Estos cuestionarios y
                    encuestas estarán diseñados generalmente de manera que se puedan responder
                    sin añadir ningún Dato Personal. Si, a pesar de ello, indica algún Dato
                    Personal en un cuestionario o encuesta, ETC Iberoamérica podrá utilizarlo
                    para mejorar sus productos y servicios.</span><span lang=ES-GT
                    style='font-size:12.0pt;font-family:"Times New Roman",serif;mso-fareast-font-family:
                    "Times New Roman";mso-font-kerning:0pt;mso-ligatures:none;mso-ansi-language:
                    ES-GT'><o:p></o:p></span></p>
                    <p class=MsoNormal style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
                    margin-left:36.0pt;margin-bottom:.0001pt;line-height:normal'><span
                    lang=ES-GT style='font-family:"Arial",sans-serif;mso-fareast-font-family:
                    "Times New Roman";color:black;mso-font-kerning:0pt;mso-ligatures:none;
                    mso-ansi-language:ES-GT'>Creación de conjuntos de datos anónimos. ETC
                    Iberoamérica puede anonimizar los Datos Personales suministrados en base a
                    esta Declaración de Privacidad para crear conjuntos de datos anónimos que se
                    utilizarán para mejorar sus productos y servicios y los de sus filiales.</span><span
                    lang=ES-GT style='font-size:12.0pt;font-family:"Times New Roman",serif;
                    mso-fareast-font-family:"Times New Roman";mso-font-kerning:0pt;mso-ligatures:
                    none;mso-ansi-language:ES-GT'><o:p></o:p></span></p>
                    <p class=MsoNormal style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
                    margin-left:36.0pt;margin-bottom:.0001pt;line-height:normal'><span
                    lang=ES-GT style='font-family:"Arial",sans-serif;mso-fareast-font-family:
                    "Times New Roman";color:black;mso-font-kerning:0pt;mso-ligatures:none;
                    mso-ansi-language:ES-GT'>Grabación de llamadas, video conferencias y
                    conversaciones de chat con fines de mejora de la calidad. En el caso de llamadas
                    telefónicas, video conferencias y sesiones de chat, ETC Iberoamérica puede
                    grabar dichas llamadas (después de informarle debidamente al respecto durante
                    la llamada y antes de que se inicie la grabación) o las sesiones de chat para
                    mejorar la calidad de los servicios de ETC Iberoamérica.</span><span
                    lang=ES-GT style='font-size:12.0pt;font-family:"Times New Roman",serif;
                    mso-fareast-font-family:"Times New Roman";mso-font-kerning:0pt;mso-ligatures:
                    none;mso-ansi-language:ES-GT'><o:p></o:p></span></p>
                    <p class=MsoNormal style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
                    margin-left:36.0pt;margin-bottom:.0001pt;line-height:normal'><span
                    lang=ES-GT style='font-family:"Arial",sans-serif;mso-fareast-font-family:
                    "Times New Roman";color:black;mso-font-kerning:0pt;mso-ligatures:none;
                    mso-ansi-language:ES-GT'>Para mantenerle actualizado/solicitar su opinión. En
                    el contexto de una relación comercial entre usted y ETC Iberoamérica, ETC
                    Iberoamérica puede informarle, siempre que las leyes locales lo permitan,
                    acerca de los productos o servicios (incluidos seminarios web, seminarios o
                    eventos) similares o relacionados con los productos y servicios que ya haya
                    adquirido o usado a través de ETC Iberoamérica. Además, en aquellos casos en
                    los que haya asistido a un seminario web, seminario o evento de ETC
                    Iberoamérica, o adquirido productos o servicios de ETC Iberoamérica, ETC
                    Iberoamérica podrá ponerse en contacto con usted para solicitarle su opinión
                    en relación con la mejora de dicho seminario web, seminario, evento, producto
                    o servicio.</span><span lang=ES-GT style='font-size:12.0pt;font-family:"Times New Roman",serif;
                    mso-fareast-font-family:"Times New Roman";mso-font-kerning:0pt;mso-ligatures:
                    none;mso-ansi-language:ES-GT'><o:p></o:p></span></p>
                    <ul style='margin-top:0cm' type=disc>
                    <li class=MsoNormal style='color:black;margin-bottom:0cm;margin-bottom:.0001pt;
                        line-height:normal;mso-list:l3 level1 lfo4;tab-stops:list 36.0pt'><span
                        lang=ES-GT style='font-family:"Arial",sans-serif;mso-fareast-font-family:
                        "Times New Roman";mso-font-kerning:0pt;mso-ligatures:none;mso-ansi-language:
                        ES-GT'>Derecho de Oposición: Puede oponerse al uso de su Datos
                        Personales por parte de ETC Iberoamérica con las finalidades mencionadas
                        anteriormente en cualquier momento anulando su suscripción
                        en&nbsp;www.etciberoamerica.com.gt. Si lo hace, ETC Iberoamérica dejará
                        de utilizar sus Datos Personales con las finalidades anteriores (es
                        decir, conforme a uno de los intereses legítimos especificados
                        anteriormente) y los eliminará de sus sistemas, salvo que ETC
                        Iberoamérica tenga derecho a utilizar dichos Datos Personales con otra
                        finalidad especificada en esta Declaración de Privacidad, o ETC
                        Iberoamérica determine y demuestre un interés legítimo imperativo para
                        continuar tratando sus Datos Personales.</span><span lang=ES-GT
                        style='font-size:12.0pt;font-family:"Times New Roman",serif;mso-fareast-font-family:
                        "Times New Roman";mso-font-kerning:0pt;mso-ligatures:none;mso-ansi-language:
                        ES-GT'><o:p></o:p></span></li>
                    </ul>
                    </td>
                    </tr>
                    <tr style='mso-yfti-irow:6'>
                    <td style='padding:0cm 0cm 0cm 0cm'></td>
                    </tr>
                    <tr style='mso-yfti-irow:7'>
                    <td style='padding:0cm 0cm 0cm 0cm'></td>
                    </tr>
                    <tr style='mso-yfti-irow:8'>
                    <td style='padding:0cm 0cm 0cm 0cm'></td>
                    </tr>
                    <tr style='mso-yfti-irow:9'>
                    <td style='padding:0cm 0cm 0cm 0cm'></td>
                    </tr>
                    <tr style='mso-yfti-irow:10'>
                    <td style='padding:0cm 0cm 0cm 0cm'>
                    <p class=MsoNormal style='margin-bottom:0cm;margin-bottom:.0001pt;line-height:
                    normal'><span lang=ES-GT style='font-family:"Arial",sans-serif;mso-fareast-font-family:
                    "Times New Roman";color:black;mso-font-kerning:0pt;mso-ligatures:none;
                    mso-ansi-language:ES-GT'>&nbsp;</span><span lang=ES-GT style='font-size:12.0pt;
                    font-family:"Times New Roman",serif;mso-fareast-font-family:"Times New Roman";
                    mso-font-kerning:0pt;mso-ligatures:none;mso-ansi-language:ES-GT'><o:p></o:p></span></p>
                    </td>
                    </tr>
                    <tr style='mso-yfti-irow:11'>
                    <td style='padding:0cm 0cm 0cm 0cm'>
                    <p class=MsoNormal style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
                    margin-left:36.0pt;margin-bottom:.0001pt;text-indent:-18.0pt;line-height:
                    normal'><b><span lang=ES-GT style='font-family:"Arial",sans-serif;mso-fareast-font-family:
                    "Times New Roman";color:black;mso-font-kerning:0pt;mso-ligatures:none;
                    mso-ansi-language:ES-GT'>B.</span></b><b><span lang=ES-GT style='font-size:
                    7.0pt;font-family:"Times New Roman",serif;mso-fareast-font-family:"Times New Roman";
                    color:black;mso-font-kerning:0pt;mso-ligatures:none;mso-ansi-language:ES-GT'>&nbsp;&nbsp;&nbsp;&nbsp;</span></b><b><span
                    lang=ES-GT style='font-family:"Arial",sans-serif;mso-fareast-font-family:
                    "Times New Roman";color:black;mso-font-kerning:0pt;mso-ligatures:none;
                    mso-ansi-language:ES-GT'>¿Qué datos personales utilizaremos para estos fines?</span></b><span
                    lang=ES-GT style='font-size:12.0pt;font-family:"Times New Roman",serif;
                    mso-fareast-font-family:"Times New Roman";mso-font-kerning:0pt;mso-ligatures:
                    none;mso-ansi-language:ES-GT'><o:p></o:p></span></p>
                    </td>
                    </tr>
                    <tr style='mso-yfti-irow:12'>
                    <td style='padding:0cm 0cm 0cm 0cm'>
                    <p class=MsoNormal style='margin-bottom:0cm;margin-bottom:.0001pt;line-height:
                    normal'><span lang=ES-GT style='font-family:"Arial",sans-serif;mso-fareast-font-family:
                    "Times New Roman";color:black;mso-font-kerning:0pt;mso-ligatures:none;
                    mso-ansi-language:ES-GT'>&nbsp;</span><span lang=ES-GT style='font-size:12.0pt;
                    font-family:"Times New Roman",serif;mso-fareast-font-family:"Times New Roman";
                    mso-font-kerning:0pt;mso-ligatures:none;mso-ansi-language:ES-GT'><o:p></o:p></span></p>
                    </td>
                    </tr>
                    <tr style='mso-yfti-irow:13;mso-yfti-lastrow:yes'>
                    <td style='padding:0cm 0cm 0cm 0cm'>
                    <p class=MsoNormal style='margin-bottom:0cm;margin-bottom:.0001pt;line-height:
                    normal'><span lang=ES-GT style='font-family:"Arial",sans-serif;mso-fareast-font-family:
                    "Times New Roman";color:black;mso-font-kerning:0pt;mso-ligatures:none;
                    mso-ansi-language:ES-GT'>Para llevar a cabo las finalidades descritas en el
                    presente aviso de privacidad, utilizaremos los siguientes datos personales:</span><span
                    lang=ES-GT style='font-size:12.0pt;font-family:"Times New Roman",serif;
                    mso-fareast-font-family:"Times New Roman";mso-font-kerning:0pt;mso-ligatures:
                    none;mso-ansi-language:ES-GT'><o:p></o:p></span></p>
                    <ul style='margin-top:0cm' type=disc>
                    <li class=MsoNormal style='color:black;margin-bottom:0cm;margin-bottom:.0001pt;
                        line-height:normal;mso-list:l2 level1 lfo5;tab-stops:list 36.0pt'><span
                        lang=EN-US style='font-family:"Arial",sans-serif;mso-fareast-font-family:
                        "Times New Roman";mso-font-kerning:0pt;mso-ligatures:none'>Nombre</span><span
                        lang=EN-US style='font-size:12.0pt;font-family:"Times New Roman",serif;
                        mso-fareast-font-family:"Times New Roman";mso-font-kerning:0pt;
                        mso-ligatures:none'><o:p></o:p></span></li>
                    <li class=MsoNormal style='color:black;margin-bottom:0cm;margin-bottom:.0001pt;
                        line-height:normal;mso-list:l2 level1 lfo5;tab-stops:list 36.0pt'><span
                        lang=ES-GT style='font-family:"Arial",sans-serif;mso-fareast-font-family:
                        "Times New Roman";mso-font-kerning:0pt;mso-ligatures:none;mso-ansi-language:
                        ES-GT'>Identificación tributaria.</span><span lang=ES-GT
                        style='font-size:12.0pt;font-family:"Times New Roman",serif;mso-fareast-font-family:
                        "Times New Roman";mso-font-kerning:0pt;mso-ligatures:none;mso-ansi-language:
                        ES-GT'><o:p></o:p></span></li>
                    <li class=MsoNormal style='color:black;margin-bottom:0cm;margin-bottom:.0001pt;
                        line-height:normal;mso-list:l2 level1 lfo5;tab-stops:list 36.0pt'><span
                        lang=ES-GT style='font-family:"Arial",sans-serif;mso-fareast-font-family:
                        "Times New Roman";mso-font-kerning:0pt;mso-ligatures:none;mso-ansi-language:
                        ES-GT'>Número de identificación personal</span><span lang=ES-GT
                        style='font-size:12.0pt;font-family:"Times New Roman",serif;mso-fareast-font-family:
                        "Times New Roman";mso-font-kerning:0pt;mso-ligatures:none;mso-ansi-language:
                        ES-GT'><o:p></o:p></span></li>
                    <li class=MsoNormal style='color:black;margin-bottom:0cm;margin-bottom:.0001pt;
                        line-height:normal;mso-list:l2 level1 lfo5;tab-stops:list 36.0pt'><span
                        lang=EN-US style='font-family:"Arial",sans-serif;mso-fareast-font-family:
                        "Times New Roman";mso-font-kerning:0pt;mso-ligatures:none'>Lugar de
                        nacimiento</span><span lang=EN-US style='font-size:12.0pt;font-family:
                        "Times New Roman",serif;mso-fareast-font-family:"Times New Roman";
                        mso-font-kerning:0pt;mso-ligatures:none'><o:p></o:p></span></li>
                    <li class=MsoNormal style='color:black;margin-bottom:0cm;margin-bottom:.0001pt;
                        line-height:normal;mso-list:l2 level1 lfo5;tab-stops:list 36.0pt'><span
                        lang=EN-US style='font-family:"Arial",sans-serif;mso-fareast-font-family:
                        "Times New Roman";mso-font-kerning:0pt;mso-ligatures:none'>Fecha de
                        nacimiento</span><span lang=EN-US style='font-size:12.0pt;font-family:
                        "Times New Roman",serif;mso-fareast-font-family:"Times New Roman";
                        mso-font-kerning:0pt;mso-ligatures:none'><o:p></o:p></span></li>
                    <li class=MsoNormal style='color:black;margin-bottom:0cm;margin-bottom:.0001pt;
                        line-height:normal;mso-list:l2 level1 lfo5;tab-stops:list 36.0pt'><span
                        lang=EN-US style='font-family:"Arial",sans-serif;mso-fareast-font-family:
                        "Times New Roman";mso-font-kerning:0pt;mso-ligatures:none'>Nacionalidad</span><span
                        lang=EN-US style='font-size:12.0pt;font-family:"Times New Roman",serif;
                        mso-fareast-font-family:"Times New Roman";mso-font-kerning:0pt;
                        mso-ligatures:none'><o:p></o:p></span></li>
                    <li class=MsoNormal style='color:black;margin-bottom:0cm;margin-bottom:.0001pt;
                        line-height:normal;mso-list:l2 level1 lfo5;tab-stops:list 36.0pt'><span
                        lang=ES-GT style='font-family:"Arial",sans-serif;mso-fareast-font-family:
                        "Times New Roman";mso-font-kerning:0pt;mso-ligatures:none;mso-ansi-language:
                        ES-GT'>Domicilio personal y/o empresarial</span><span lang=ES-GT
                        style='font-size:12.0pt;font-family:"Times New Roman",serif;mso-fareast-font-family:
                        "Times New Roman";mso-font-kerning:0pt;mso-ligatures:none;mso-ansi-language:
                        ES-GT'><o:p></o:p></span></li>
                    <li class=MsoNormal style='color:black;margin-bottom:0cm;margin-bottom:.0001pt;
                        line-height:normal;mso-list:l2 level1 lfo5;tab-stops:list 36.0pt'><span
                        lang=ES-GT style='font-family:"Arial",sans-serif;mso-fareast-font-family:
                        "Times New Roman";mso-font-kerning:0pt;mso-ligatures:none;mso-ansi-language:
                        ES-GT'>Teléfono particular y/o empresarial</span><span lang=ES-GT
                        style='font-size:12.0pt;font-family:"Times New Roman",serif;mso-fareast-font-family:
                        "Times New Roman";mso-font-kerning:0pt;mso-ligatures:none;mso-ansi-language:
                        ES-GT'><o:p></o:p></span></li>
                    <li class=MsoNormal style='color:black;margin-bottom:0cm;margin-bottom:.0001pt;
                        line-height:normal;mso-list:l2 level1 lfo5;tab-stops:list 36.0pt'><span
                        class=SpellE><span lang=EN-US style='font-family:"Arial",sans-serif;
                        mso-fareast-font-family:"Times New Roman";mso-font-kerning:0pt;
                        mso-ligatures:none'>Teléfono</span></span><span lang=EN-US
                        style='font-family:"Arial",sans-serif;mso-fareast-font-family:"Times New Roman";
                        mso-font-kerning:0pt;mso-ligatures:none'> <span class=SpellE>celular</span></span><span
                        lang=EN-US style='font-size:12.0pt;font-family:"Times New Roman",serif;
                        mso-fareast-font-family:"Times New Roman";mso-font-kerning:0pt;
                        mso-ligatures:none'><o:p></o:p></span></li>
                    <li class=MsoNormal style='color:black;margin-bottom:0cm;margin-bottom:.0001pt;
                        line-height:normal;mso-list:l2 level1 lfo5;tab-stops:list 36.0pt'><span
                        lang=EN-US style='font-family:"Arial",sans-serif;mso-fareast-font-family:
                        "Times New Roman";mso-font-kerning:0pt;mso-ligatures:none'>Correo
                        electrónico</span><span lang=EN-US style='font-size:12.0pt;font-family:
                        "Times New Roman",serif;mso-fareast-font-family:"Times New Roman";
                        mso-font-kerning:0pt;mso-ligatures:none'><o:p></o:p></span></li>
                    <li class=MsoNormal style='color:black;margin-bottom:0cm;margin-bottom:.0001pt;
                        line-height:normal;mso-list:l2 level1 lfo5;tab-stops:list 36.0pt'><span
                        lang=EN-US style='font-family:"Arial",sans-serif;mso-fareast-font-family:
                        "Times New Roman";mso-font-kerning:0pt;mso-ligatures:none'>Edad</span><span
                        lang=EN-US style='font-size:12.0pt;font-family:"Times New Roman",serif;
                        mso-fareast-font-family:"Times New Roman";mso-font-kerning:0pt;
                        mso-ligatures:none'><o:p></o:p></span></li>
                    <li class=MsoNormal style='color:black;margin-bottom:0cm;margin-bottom:.0001pt;
                        line-height:normal;mso-list:l2 level1 lfo5;tab-stops:list 36.0pt'><span
                        lang=EN-US style='font-family:"Arial",sans-serif;mso-fareast-font-family:
                        "Times New Roman";mso-font-kerning:0pt;mso-ligatures:none'>Puesto o
                        cargo que desempeña</span><span lang=EN-US style='font-size:12.0pt;
                        font-family:"Times New Roman",serif;mso-fareast-font-family:"Times New Roman";
                        mso-font-kerning:0pt;mso-ligatures:none'><o:p></o:p></span></li>
                    <li class=MsoNormal style='color:black;margin-bottom:0cm;margin-bottom:.0001pt;
                        line-height:normal;mso-list:l2 level1 lfo5;tab-stops:list 36.0pt'><span
                        lang=EN-US style='font-family:"Arial",sans-serif;mso-fareast-font-family:
                        "Times New Roman";mso-font-kerning:0pt;mso-ligatures:none'>Domicilio de
                        trabajo</span><span lang=EN-US style='font-size:12.0pt;font-family:"Times New Roman",serif;
                        mso-fareast-font-family:"Times New Roman";mso-font-kerning:0pt;
                        mso-ligatures:none'><o:p></o:p></span></li>
                    <li class=MsoNormal style='color:black;margin-bottom:0cm;margin-bottom:.0001pt;
                        line-height:normal;mso-list:l2 level1 lfo5;tab-stops:list 36.0pt'><span
                        lang=EN-US style='font-family:"Arial",sans-serif;mso-fareast-font-family:
                        "Times New Roman";mso-font-kerning:0pt;mso-ligatures:none'>Correo
                        electrónico institucional</span><span lang=EN-US style='font-size:12.0pt;
                        font-family:"Times New Roman",serif;mso-fareast-font-family:"Times New Roman";
                        mso-font-kerning:0pt;mso-ligatures:none'><o:p></o:p></span></li>
                    <li class=MsoNormal style='color:black;margin-bottom:0cm;margin-bottom:.0001pt;
                        line-height:normal;mso-list:l2 level1 lfo5;tab-stops:list 36.0pt'><span
                        lang=EN-US style='font-family:"Arial",sans-serif;mso-fareast-font-family:
                        "Times New Roman";mso-font-kerning:0pt;mso-ligatures:none'>Teléfono
                        institucional</span><span lang=EN-US style='font-size:12.0pt;font-family:
                        "Times New Roman",serif;mso-fareast-font-family:"Times New Roman";
                        mso-font-kerning:0pt;mso-ligatures:none'><o:p></o:p></span></li>
                    <li class=MsoNormal style='color:black;margin-bottom:0cm;margin-bottom:.0001pt;
                        line-height:normal;mso-list:l2 level1 lfo5;tab-stops:list 36.0pt'><span
                        lang=EN-US style='font-family:"Arial",sans-serif;mso-fareast-font-family:
                        "Times New Roman";mso-font-kerning:0pt;mso-ligatures:none'>Trayectoria
                        educativa</span><span lang=EN-US style='font-size:12.0pt;font-family:
                        "Times New Roman",serif;mso-fareast-font-family:"Times New Roman";
                        mso-font-kerning:0pt;mso-ligatures:none'><o:p></o:p></span></li>
                    <li class=MsoNormal style='color:black;margin-bottom:0cm;margin-bottom:.0001pt;
                        line-height:normal;mso-list:l2 level1 lfo5;tab-stops:list 36.0pt'><span
                        lang=EN-US style='font-family:"Arial",sans-serif;mso-fareast-font-family:
                        "Times New Roman";mso-font-kerning:0pt;mso-ligatures:none'>Datos de
                        identificación</span><span lang=EN-US style='font-size:12.0pt;
                        font-family:"Times New Roman",serif;mso-fareast-font-family:"Times New Roman";
                        mso-font-kerning:0pt;mso-ligatures:none'><o:p></o:p></span></li>
                    <li class=MsoNormal style='color:black;margin-bottom:0cm;margin-bottom:.0001pt;
                        line-height:normal;mso-list:l2 level1 lfo5;tab-stops:list 36.0pt'><span
                        lang=EN-US style='font-family:"Arial",sans-serif;mso-fareast-font-family:
                        "Times New Roman";mso-font-kerning:0pt;mso-ligatures:none'>Datos de
                        contacto</span><span lang=EN-US style='font-size:12.0pt;font-family:
                        "Times New Roman",serif;mso-fareast-font-family:"Times New Roman";
                        mso-font-kerning:0pt;mso-ligatures:none'><o:p></o:p></span></li>
                    <li class=MsoNormal style='color:black;margin-bottom:0cm;margin-bottom:.0001pt;
                        line-height:normal;mso-list:l2 level1 lfo5;tab-stops:list 36.0pt'><span
                        lang=EN-US style='font-family:"Arial",sans-serif;mso-fareast-font-family:
                        "Times New Roman";mso-font-kerning:0pt;mso-ligatures:none'>Datos
                        laborales</span><span lang=EN-US style='font-size:12.0pt;font-family:
                        "Times New Roman",serif;mso-fareast-font-family:"Times New Roman";
                        mso-font-kerning:0pt;mso-ligatures:none'><o:p></o:p></span></li>
                    <li class=MsoNormal style='color:black;margin-bottom:0cm;margin-bottom:.0001pt;
                        line-height:normal;mso-list:l2 level1 lfo5;tab-stops:list 36.0pt'><span
                        lang=ES-GT style='font-family:"Arial",sans-serif;mso-fareast-font-family:
                        "Times New Roman";mso-font-kerning:0pt;mso-ligatures:none;mso-ansi-language:
                        ES-GT'>Datos patrimoniales y/o financieros</span><span lang=ES-GT
                        style='font-size:12.0pt;font-family:"Times New Roman",serif;mso-fareast-font-family:
                        "Times New Roman";mso-font-kerning:0pt;mso-ligatures:none;mso-ansi-language:
                        ES-GT'><o:p></o:p></span></li>
                    </ul>
                    </td>
                    </tr>
                    </table>

                    <p class=MsoNormal style='margin-bottom:0cm;margin-bottom:.0001pt;line-height:
                    normal'><span lang=ES-GT style='font-size:13.5pt;font-family:"Times New Roman",serif;
                    mso-fareast-font-family:"Times New Roman";color:black;display:none;mso-hide:
                    all;mso-font-kerning:0pt;mso-ligatures:none;mso-ansi-language:ES-GT'><o:p>&nbsp;</o:p></span></p>

                    <div align=center>

                    <table class=MsoNormalTable border=0 cellspacing=0 cellpadding=0 width="100%"
                    style='width:100.0%;border-collapse:collapse;mso-yfti-tbllook:1184;mso-padding-alt:
                    0cm 0cm 0cm 0cm'>
                    <tr style='mso-yfti-irow:0;mso-yfti-firstrow:yes'>
                    <td style='padding:0cm 0cm 0cm 0cm'>
                    <p class=MsoNormal style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
                    margin-left:21.3pt;margin-bottom:.0001pt;line-height:normal'><b><span
                    lang=ES-GT style='font-family:"Arial",sans-serif;mso-fareast-font-family:
                    "Times New Roman";color:black;mso-font-kerning:0pt;mso-ligatures:none;
                    mso-ansi-language:ES-GT'><br>
                    C. ¿Cómo puede acceder, rectificar o cancelar sus datos personales, u
                    oponerse a su uso?</span></b><span lang=ES-GT style='font-size:12.0pt;
                    font-family:"Times New Roman",serif;mso-fareast-font-family:"Times New Roman";
                    mso-font-kerning:0pt;mso-ligatures:none;mso-ansi-language:ES-GT'><o:p></o:p></span></p>
                    </td>
                    </tr>
                    <tr style='mso-yfti-irow:1'>
                    <td style='padding:0cm 0cm 0cm 0cm'>
                    <p class=MsoNormal style='margin-bottom:0cm;margin-bottom:.0001pt;line-height:
                    normal'><span lang=ES-GT style='font-family:"Arial",sans-serif;mso-fareast-font-family:
                    "Times New Roman";color:black;mso-font-kerning:0pt;mso-ligatures:none;
                    mso-ansi-language:ES-GT'>&nbsp;</span><span lang=ES-GT style='font-size:12.0pt;
                    font-family:"Times New Roman",serif;mso-fareast-font-family:"Times New Roman";
                    mso-font-kerning:0pt;mso-ligatures:none;mso-ansi-language:ES-GT'><o:p></o:p></span></p>
                    </td>
                    </tr>
                    <tr style='mso-yfti-irow:2'>
                    <td style='padding:0cm 0cm 0cm 0cm'>
                    <p class=MsoNormal style='margin-bottom:0cm;margin-bottom:.0001pt;line-height:
                    normal'><span lang=ES-GT style='font-family:"Arial",sans-serif;mso-fareast-font-family:
                    "Times New Roman";color:black;mso-font-kerning:0pt;mso-ligatures:none;
                    mso-ansi-language:ES-GT'>Usted tiene derecho a conocer qué datos personales
                    tenemos de usted, para qué los utilizamos y las condiciones del uso que les
                    damos (Acceso). Asimismo, es su derecho solicitar la corrección de su
                    información personal en caso de que esté desactualizada, sea inexacta o
                    incompleta (Rectificación) que la eliminemos de nuestros registros o bases de
                    datos cuando considere que la misma no esté siendo utilizada adecuadamente
                    (Cancelación) así como oponerse al uso de sus datos personales para fines
                    específicos (Oposición).</span><span lang=ES-GT style='font-size:12.0pt;
                    font-family:"Times New Roman",serif;mso-fareast-font-family:"Times New Roman";
                    mso-font-kerning:0pt;mso-ligatures:none;mso-ansi-language:ES-GT'><o:p></o:p></span></p>
                    </td>
                    </tr>
                    <tr style='mso-yfti-irow:3'>
                    <td style='padding:0cm 0cm 0cm 0cm'>
                    <p class=MsoNormal style='margin-bottom:0cm;margin-bottom:.0001pt;line-height:
                    normal'><span lang=ES-GT style='font-family:"Arial",sans-serif;mso-fareast-font-family:
                    "Times New Roman";color:black;mso-font-kerning:0pt;mso-ligatures:none;
                    mso-ansi-language:ES-GT'>&nbsp;</span><span lang=ES-GT style='font-size:12.0pt;
                    font-family:"Times New Roman",serif;mso-fareast-font-family:"Times New Roman";
                    mso-font-kerning:0pt;mso-ligatures:none;mso-ansi-language:ES-GT'><o:p></o:p></span></p>
                    </td>
                    </tr>
                    <tr style='mso-yfti-irow:4;mso-yfti-lastrow:yes'>
                    <td style='padding:0cm 0cm 0cm 0cm'>
                    <p class=MsoNormal style='margin-bottom:0cm;margin-bottom:.0001pt;line-height:
                    normal'><span lang=ES-GT style='font-family:"Arial",sans-serif;mso-fareast-font-family:
                    "Times New Roman";color:black;mso-font-kerning:0pt;mso-ligatures:none;
                    mso-ansi-language:ES-GT'>Para el ejercicio de cualquiera de los derechos, usted
                    deberá presentar la solicitud respectiva a través del siguiente medio: </span><span
                    lang=EN-US><a href="http://www.etciberoamerica.com.gt"><span lang=ES-GT
                    style='font-family:"Arial",sans-serif;mso-fareast-font-family:"Times New Roman";
                    mso-font-kerning:0pt;mso-ligatures:none;mso-ansi-language:ES-GT'>www.etciberoamerica.com.gt</span></a></span><span
                    lang=ES-GT style='font-family:"Arial",sans-serif;mso-fareast-font-family:
                    "Times New Roman";color:black;mso-font-kerning:0pt;mso-ligatures:none;
                    mso-ansi-language:ES-GT'>. <o:p></o:p></span></p>
                    </td>
                    </tr>
                    </table>

                    </div>

                    <p class=MsoNormal style='margin-bottom:0cm;margin-bottom:.0001pt;line-height:
                    normal'><span lang=ES-GT style='font-family:"Arial",sans-serif;mso-fareast-font-family:
                    "Times New Roman";color:black;mso-font-kerning:0pt;mso-ligatures:none;
                    mso-ansi-language:ES-GT'>&nbsp;</span><span lang=ES-GT style='font-size:13.5pt;
                    font-family:"Times New Roman",serif;mso-fareast-font-family:"Times New Roman";
                    color:black;mso-font-kerning:0pt;mso-ligatures:none;mso-ansi-language:ES-GT'><o:p></o:p></span></p>

                    <table class=MsoNormalTable border=0 cellspacing=0 cellpadding=0 align=left
                    width="100%" style='width:100.0%;border-collapse:collapse;mso-yfti-tbllook:
                    1184;mso-table-lspace:2.25pt;mso-table-rspace:2.25pt;mso-table-anchor-vertical:
                    paragraph;mso-table-anchor-horizontal:column;mso-table-left:left;mso-padding-alt:
                    0cm 0cm 0cm 0cm'>
                    <tr style='mso-yfti-irow:0;mso-yfti-firstrow:yes;mso-yfti-lastrow:yes'>
                    <td style='padding:0cm 0cm 0cm 0cm'>
                    <p class=MsoNormal style='margin-bottom:0cm;margin-bottom:.0001pt;line-height:
                    normal;mso-element:frame;mso-element-frame-hspace:2.25pt;mso-element-wrap:
                    around;mso-element-anchor-vertical:paragraph;mso-element-anchor-horizontal:
                    column;mso-height-rule:exactly'><span lang=ES-GT style='font-family:"Arial",sans-serif;
                    mso-fareast-font-family:"Times New Roman";color:black;mso-font-kerning:0pt;
                    mso-ligatures:none;mso-ansi-language:ES-GT'>Para conocer el procedimiento y
                    requisitos para el ejercicio de los derechos, ponemos a su disposición, el
                    siguiente medio:<br>
                    <br>
                    www.etciberoamerica.com.gt</span><span lang=ES-GT style='font-size:12.0pt;
                    font-family:"Times New Roman",serif;mso-fareast-font-family:"Times New Roman";
                    mso-font-kerning:0pt;mso-ligatures:none;mso-ansi-language:ES-GT'><o:p></o:p></span></p>
                    </td>
                    </tr>
                    </table>

                    <p class=MsoNormal style='margin-bottom:0cm;margin-bottom:.0001pt;line-height:
                    normal'><span lang=ES-GT style='font-size:12.0pt;font-family:"Times New Roman",serif;
                    mso-fareast-font-family:"Times New Roman";display:none;mso-hide:all;mso-font-kerning:
                    0pt;mso-ligatures:none;mso-ansi-language:ES-GT'><o:p>&nbsp;</o:p></span></p>

                    <table class=MsoNormalTable border=0 cellspacing=0 cellpadding=0 align=left
                    width="100%" style='width:100.0%;border-collapse:collapse;mso-yfti-tbllook:
                    1184;mso-table-lspace:2.25pt;mso-table-rspace:2.25pt;mso-table-anchor-vertical:
                    paragraph;mso-table-anchor-horizontal:column;mso-table-left:left;mso-padding-alt:
                    0cm 0cm 0cm 0cm'>
                    <tr style='mso-yfti-irow:0;mso-yfti-firstrow:yes'>
                    <td style='padding:0cm 0cm 0cm 0cm'>
                    <p class=MsoNormal style='margin-bottom:0cm;margin-bottom:.0001pt;line-height:
                    normal;mso-element:frame;mso-element-frame-hspace:2.25pt;mso-element-wrap:
                    around;mso-element-anchor-vertical:paragraph;mso-element-anchor-horizontal:
                    column;mso-height-rule:exactly'><span lang=ES-GT style='font-family:"Arial",sans-serif;
                    mso-fareast-font-family:"Times New Roman";color:black;mso-font-kerning:0pt;
                    mso-ligatures:none;mso-ansi-language:ES-GT'><br>
                    Los datos de contacto departamento de datos personales, que está a cargo de
                    dar trámite a las solicitudes de estos derechos, son los siguientes:</span><span
                    lang=ES-GT style='font-size:12.0pt;font-family:"Times New Roman",serif;
                    mso-fareast-font-family:"Times New Roman";mso-font-kerning:0pt;mso-ligatures:
                    none;mso-ansi-language:ES-GT'><o:p></o:p></span></p>
                    </td>
                    </tr>
                    <tr style='mso-yfti-irow:1'>
                    <td style='padding:0cm 0cm 0cm 0cm'>
                    <p class=MsoNormal style='margin-bottom:0cm;margin-bottom:.0001pt;line-height:
                    normal;mso-element:frame;mso-element-frame-hspace:2.25pt;mso-element-wrap:
                    around;mso-element-anchor-vertical:paragraph;mso-element-anchor-horizontal:
                    column;mso-height-rule:exactly'><span lang=ES-GT style='font-family:"Arial",sans-serif;
                    mso-fareast-font-family:"Times New Roman";color:black;mso-font-kerning:0pt;
                    mso-ligatures:none;mso-ansi-language:ES-GT'>&nbsp;</span><span lang=ES-GT
                    style='font-size:12.0pt;font-family:"Times New Roman",serif;mso-fareast-font-family:
                    "Times New Roman";mso-font-kerning:0pt;mso-ligatures:none;mso-ansi-language:
                    ES-GT'><o:p></o:p></span></p>
                    </td>
                    </tr>
                    <tr style='mso-yfti-irow:2'>
                    <td style='padding:0cm 0cm 0cm 0cm'>
                    <p class=MsoNormal style='margin-bottom:0cm;margin-bottom:.0001pt;line-height:
                    normal;mso-element:frame;mso-element-frame-hspace:2.25pt;mso-element-wrap:
                    around;mso-element-anchor-vertical:paragraph;mso-element-anchor-horizontal:
                    column;mso-height-rule:exactly'><span lang=ES-GT style='font-family:"Arial",sans-serif;
                    mso-fareast-font-family:"Times New Roman";color:black;mso-font-kerning:0pt;
                    mso-ligatures:none;mso-ansi-language:ES-GT'>a) Nombre departamento de datos
                    personales: Área de cumplimiento.<br>
                    b) Domicilio:&nbsp;&nbsp;15 avenida 5-65 zona 13, código postal 01013, ciudad
                    de Guatemala, Guatemala.<br>
                    c) Correo electrónico: cumplimiento@etciberoamerica.com.gt<o:p></o:p></span></p>
                    <p class=MsoNormal style='margin-bottom:0cm;margin-bottom:.0001pt;line-height:
                    normal;mso-element:frame;mso-element-frame-hspace:2.25pt;mso-element-wrap:
                    around;mso-element-anchor-vertical:paragraph;mso-element-anchor-horizontal:
                    column;mso-height-rule:exactly'><span lang=ES-GT style='font-family:"Arial",sans-serif;
                    mso-fareast-font-family:"Times New Roman";color:black;mso-font-kerning:0pt;
                    mso-ligatures:none;mso-ansi-language:ES-GT'><o:p>&nbsp;</o:p></span></p>
                    <p class=MsoNormal style='margin-bottom:0cm;margin-bottom:.0001pt;line-height:
                    normal;mso-element:frame;mso-element-frame-hspace:2.25pt;mso-element-wrap:
                    around;mso-element-anchor-vertical:paragraph;mso-element-anchor-horizontal:
                    column;mso-height-rule:exactly'><b><span lang=ES-GT style='font-family:"Arial",sans-serif;
                    mso-fareast-font-family:"Times New Roman";color:black;mso-font-kerning:0pt;
                    mso-ligatures:none;mso-ansi-language:ES-GT'>D. ¿Cómo protegemos sus datos personales?<o:p></o:p></span></b></p>
                    <p class=MsoNormal style='margin-bottom:0cm;margin-bottom:.0001pt;line-height:
                    normal;mso-element:frame;mso-element-frame-hspace:2.25pt;mso-element-wrap:
                    around;mso-element-anchor-vertical:paragraph;mso-element-anchor-horizontal:
                    column;mso-height-rule:exactly'><span lang=ES-GT style='font-family:"Arial",sans-serif;
                    mso-fareast-font-family:"Times New Roman";color:black;mso-font-kerning:0pt;
                    mso-ligatures:none;mso-ansi-language:ES-GT'><o:p>&nbsp;</o:p></span></p>
                    <p class=MsoNormal style='margin-bottom:0cm;margin-bottom:.0001pt;line-height:
                    normal;mso-element:frame;mso-element-frame-hspace:2.25pt;mso-element-wrap:
                    around;mso-element-anchor-vertical:paragraph;mso-element-anchor-horizontal:
                    column;mso-height-rule:exactly'><span lang=ES-GT style='font-family:"Arial",sans-serif;
                    mso-fareast-font-family:"Times New Roman";color:black;mso-font-kerning:0pt;
                    mso-ligatures:none;mso-ansi-language:ES-GT'>Constantemente implementamos
                    medidas de seguridad físicas, electrónicas y de procedimiento diseñadas para
                    ayudarnos a proteger la información personal de todos nuestros socios,
                    clientes, empleados, aliados y prospectos interesados. ETC Iberoamérica,
                    utiliza la tecnología Secure Socket Layer (SSL/HTTPS) para cifrar su
                    información personal al crear credenciales, pedidos, realizar pagos con
                    tarjeta de crédito a través de nuestro sitio web y/o tienda en línea. Al
                    crear su perfil en los sitios de ETC Iberoamérica, elige una contraseña para
                    ayudar a proteger la información de su cuenta. Debe seleccionar una
                    contraseña segura y mantenerla segura. Puede cambiar la contraseña tantas
                    veces como desee a través de nuestro servicio en línea<br>
                    <br>
                    <b>E. Usted puede revocar su consentimiento para el uso de sus datos
                    personales</b><br>
                    <br>
                    Usted puede revocar el consentimiento que, en su caso, nos haya otorgado para
                    el tratamiento de sus datos personales. Sin embargo, es importante que tenga
                    en cuenta que no en todos los casos podremos atender su solicitud o concluir
                    el uso de forma inmediata, ya que es posible que por alguna obligación legal
                    requiramos seguir tratando sus datos personales. Asimismo, usted deberá
                    considerar que para ciertos fines, la revocación de su consentimiento, puede
                    implicar que no le podamos seguir prestando el servicio que nos solicitó, y
                    la conclusión de su relación con nosotros.<br>
                    <br>
                    Para revocar su consentimiento deberá presentar su solicitud a
                    través&nbsp;del siguiente medio:<br>
                    <br>
                    <span style='mso-spacerun:yes'></span>Correo electrónico:<span
                    style='mso-spacerun:yes'> </span>cumplimiento@etciberoamerica.com.gt</span><span
                    lang=ES-GT style='font-size:12.0pt;font-family:"Times New Roman",serif;
                    mso-fareast-font-family:"Times New Roman";mso-font-kerning:0pt;mso-ligatures:
                    none;mso-ansi-language:ES-GT'><o:p></o:p></span></p>
                    </td>
                    </tr>
                    <tr style='mso-yfti-irow:3;mso-yfti-lastrow:yes'>
                    <td style='padding:0cm 0cm 0cm 0cm'>
                    <p class=MsoNormal style='margin-bottom:12.0pt;line-height:normal;mso-element:
                    frame;mso-element-frame-hspace:2.25pt;mso-element-wrap:around;mso-element-anchor-vertical:
                    paragraph;mso-element-anchor-horizontal:column;mso-height-rule:exactly'><span
                    lang=ES-GT style='font-family:"Arial",sans-serif;mso-fareast-font-family:
                    "Times New Roman";color:black;mso-font-kerning:0pt;mso-ligatures:none;
                    mso-ansi-language:ES-GT'><br>
                    Para conocer el procedimiento y requisitos para la revocación del
                    consentimiento, ponemos a su disposicisión el siguiente medio:<br>
                    <br>
                    <span style='mso-spacerun:yes'></span>Correo electrónico:<span
                    style='mso-spacerun:yes'> </span>cumplimiento@etciberoamerica.com.gt</span><span
                    lang=ES-GT style='font-size:12.0pt;font-family:"Times New Roman",serif;
                    mso-fareast-font-family:"Times New Roman";mso-font-kerning:0pt;mso-ligatures:
                    none;mso-ansi-language:ES-GT'><o:p></o:p></span></p>
                    </td>
                    </tr>
                    </table>

                    <div align=center>

                    <table class=MsoNormalTable border=0 cellspacing=0 cellpadding=0 width="100%"
                    style='width:100.0%;border-collapse:collapse;mso-yfti-tbllook:1184;mso-padding-alt:
                    0cm 0cm 0cm 0cm'>
                    <tr style='mso-yfti-irow:0;mso-yfti-firstrow:yes'>
                    <td style='padding:0cm 0cm 0cm 0cm'>
                    <p class=MsoNormal style='margin-bottom:0cm;margin-bottom:.0001pt;line-height:
                    normal'><span lang=ES-GT style='font-family:"Arial",sans-serif;mso-fareast-font-family:
                    "Times New Roman";color:black;mso-font-kerning:0pt;mso-ligatures:none;
                    mso-ansi-language:ES-GT'>&nbsp;</span><span lang=ES-GT style='font-size:12.0pt;
                    font-family:"Times New Roman",serif;mso-fareast-font-family:"Times New Roman";
                    mso-font-kerning:0pt;mso-ligatures:none;mso-ansi-language:ES-GT'><o:p></o:p></span></p>
                    </td>
                    </tr>
                    <tr style='mso-yfti-irow:1'>
                    <td style='padding:0cm 0cm 0cm 0cm'>
                    <p class=MsoNormal style='margin-bottom:0cm;margin-bottom:.0001pt;line-height:
                    normal'><b><span lang=ES-GT style='font-family:"Arial",sans-serif;mso-fareast-font-family:
                    "Times New Roman";color:black;mso-font-kerning:0pt;mso-ligatures:none;
                    mso-ansi-language:ES-GT'>F. ¿Cómo puede limitar el uso o divulgación de su
                    información personal?</span></b><span lang=ES-GT style='font-size:12.0pt;
                    font-family:"Times New Roman",serif;mso-fareast-font-family:"Times New Roman";
                    mso-font-kerning:0pt;mso-ligatures:none;mso-ansi-language:ES-GT'><o:p></o:p></span></p>
                    </td>
                    </tr>
                    <tr style='mso-yfti-irow:2'>
                    <td style='padding:0cm 0cm 0cm 0cm'>
                    <p class=MsoNormal style='margin-bottom:0cm;margin-bottom:.0001pt;line-height:
                    normal'><span lang=ES-GT style='font-family:"Arial",sans-serif;mso-fareast-font-family:
                    "Times New Roman";color:black;mso-font-kerning:0pt;mso-ligatures:none;
                    mso-ansi-language:ES-GT'>&nbsp;</span><span lang=ES-GT style='font-size:12.0pt;
                    font-family:"Times New Roman",serif;mso-fareast-font-family:"Times New Roman";
                    mso-font-kerning:0pt;mso-ligatures:none;mso-ansi-language:ES-GT'><o:p></o:p></span></p>
                    </td>
                    </tr>
                    <tr style='mso-yfti-irow:3'>
                    <td style='padding:0cm 0cm 0cm 0cm'>
                    <p class=MsoNormal style='margin-bottom:12.0pt;line-height:normal'><span
                    lang=ES-GT style='font-family:"Arial",sans-serif;mso-fareast-font-family:
                    "Times New Roman";color:black;mso-font-kerning:0pt;mso-ligatures:none;
                    mso-ansi-language:ES-GT'>Con objeto de que usted pueda limitar el uso y
                    divulgación de su información personal, le ofrecemos los siguientes medios:<br>
                    <br>
                    Correo electrónico:<span style='mso-spacerun:yes'> </span>cumplimiento@etciberoamerica.com.gt</span><span
                    lang=ES-GT style='font-size:12.0pt;font-family:"Times New Roman",serif;
                    mso-fareast-font-family:"Times New Roman";mso-font-kerning:0pt;mso-ligatures:
                    none;mso-ansi-language:ES-GT'><o:p></o:p></span></p>
                    </td>
                    </tr>
                    <tr style='mso-yfti-irow:4;mso-yfti-lastrow:yes'>
                    <td style='padding:0cm 0cm 0cm 0cm'>
                    <p class=MsoNormal style='margin-bottom:0cm;margin-bottom:.0001pt;line-height:
                    normal'><span lang=ES-GT style='font-family:"Arial",sans-serif;mso-fareast-font-family:
                    "Times New Roman";color:black;mso-font-kerning:0pt;mso-ligatures:none;
                    mso-ansi-language:ES-GT'>&nbsp;</span><span lang=ES-GT style='font-size:12.0pt;
                    font-family:"Times New Roman",serif;mso-fareast-font-family:"Times New Roman";
                    mso-font-kerning:0pt;mso-ligatures:none;mso-ansi-language:ES-GT'><o:p></o:p></span></p>
                    </td>
                    </tr>
                    </table>

                    </div>

                    <table class=MsoNormalTable border=0 cellspacing=0 cellpadding=0 align=left
                    width="100%" style='width:100.0%;border-collapse:collapse;mso-yfti-tbllook:
                    1184;mso-table-lspace:2.25pt;mso-table-rspace:2.25pt;mso-table-anchor-vertical:
                    paragraph;mso-table-anchor-horizontal:column;mso-table-left:left;mso-padding-alt:
                    0cm 0cm 0cm 0cm'>
                    <tr style='mso-yfti-irow:0;mso-yfti-firstrow:yes'>
                    <td width="100%" style='width:100.0%;padding:0cm 0cm 0cm 0cm'>
                    <p class=MsoNormal style='margin-bottom:0cm;margin-bottom:.0001pt;line-height:
                    normal;mso-element:frame;mso-element-frame-hspace:2.25pt;mso-element-wrap:
                    around;mso-element-anchor-vertical:paragraph;mso-element-anchor-horizontal:
                    column;mso-height-rule:exactly'><b><span lang=ES-GT style='font-family:"Arial",sans-serif;
                    mso-fareast-font-family:"Times New Roman";color:black;mso-font-kerning:0pt;
                    mso-ligatures:none;mso-ansi-language:ES-GT'>G. ¿Cómo puede conocer los cambios
                    en este aviso de privacidad?</span></b><span lang=ES-GT style='font-size:
                    12.0pt;font-family:"Times New Roman",serif;mso-fareast-font-family:"Times New Roman";
                    mso-font-kerning:0pt;mso-ligatures:none;mso-ansi-language:ES-GT'><o:p></o:p></span></p>
                    </td>
                    </tr>
                    <tr style='mso-yfti-irow:1'>
                    <td style='padding:0cm 0cm 0cm 0cm'>
                    <p class=MsoNormal style='margin-bottom:0cm;margin-bottom:.0001pt;line-height:
                    normal;mso-element:frame;mso-element-frame-hspace:2.25pt;mso-element-wrap:
                    around;mso-element-anchor-vertical:paragraph;mso-element-anchor-horizontal:
                    column;mso-height-rule:exactly'><span lang=ES-GT style='font-family:"Arial",sans-serif;
                    mso-fareast-font-family:"Times New Roman";color:black;mso-font-kerning:0pt;
                    mso-ligatures:none;mso-ansi-language:ES-GT'>&nbsp;</span><span lang=ES-GT
                    style='font-size:12.0pt;font-family:"Times New Roman",serif;mso-fareast-font-family:
                    "Times New Roman";mso-font-kerning:0pt;mso-ligatures:none;mso-ansi-language:
                    ES-GT'><o:p></o:p></span></p>
                    </td>
                    </tr>
                    <tr style='mso-yfti-irow:2;mso-yfti-lastrow:yes'>
                    <td style='padding:0cm 0cm 0cm 0cm'>
                    <p class=MsoNormal style='margin-bottom:0cm;margin-bottom:.0001pt;line-height:
                    normal;mso-element:frame;mso-element-frame-hspace:2.25pt;mso-element-wrap:
                    around;mso-element-anchor-vertical:paragraph;mso-element-anchor-horizontal:
                    column;mso-height-rule:exactly'><span lang=ES-GT style='font-family:"Arial",sans-serif;
                    mso-fareast-font-family:"Times New Roman";color:black;mso-font-kerning:0pt;
                    mso-ligatures:none;mso-ansi-language:ES-GT'>El presente aviso de privacidad
                    puede sufrir modificaciones, cambios o actualizaciones derivadas de nuevos
                    requerimientos legales; de nuestras propias necesidades por los productos o
                    servicios que ofrecemos; de nuestras políticas de privacidad; de cambios en
                    nuestro modelo de negocio, o por otras causas.<br>
                    <br>
                    Nos comprometemos a mantenerlo informado sobre los cambios que pueda sufrir
                    el presente aviso de privacidad, a través de: www.etciberoamerica.com.gt<br>
                    <br>
                    El procedimiento a través del cual se llevará a cabo las notificaciones sobre
                    cambios o actualizaciones al presente aviso de privacidad es el siguiente:<br>
                    <br>
                    Correo electrónico:<span style='mso-spacerun:yes'> </span>cumplimiento@etciberoamerica.com.gt</span><span
                    lang=ES-GT style='font-size:12.0pt;font-family:"Times New Roman",serif;
                    mso-fareast-font-family:"Times New Roman";mso-font-kerning:0pt;mso-ligatures:
                    none;mso-ansi-language:ES-GT'><o:p></o:p></span></p>
                    </td>
                    </tr>
                    </table>

                    <p class=MsoNormal style='margin-bottom:0cm;margin-bottom:.0001pt;line-height:
                    normal'><span lang=ES-GT style='font-size:12.0pt;font-family:"Times New Roman",serif;
                    mso-fareast-font-family:"Times New Roman";display:none;mso-hide:all;mso-font-kerning:
                    0pt;mso-ligatures:none;mso-ansi-language:ES-GT'><o:p>&nbsp;</o:p></span></p>

                    <table class=MsoNormalTable border=0 cellspacing=0 cellpadding=0 align=left
                    width="100%" style='width:100.0%;border-collapse:collapse;mso-yfti-tbllook:
                    1184;mso-table-lspace:2.25pt;mso-table-rspace:2.25pt;mso-table-anchor-vertical:
                    paragraph;mso-table-anchor-horizontal:column;mso-table-left:left;mso-padding-alt:
                    0cm 0cm 0cm 0cm'>
                    <tr style='mso-yfti-irow:0;mso-yfti-firstrow:yes'>
                    <td style='padding:0cm 0cm 0cm 0cm'>
                    <p class=MsoNormal style='margin-bottom:0cm;margin-bottom:.0001pt;line-height:
                    normal;mso-element:frame;mso-element-frame-hspace:2.25pt;mso-element-wrap:
                    around;mso-element-anchor-vertical:paragraph;mso-element-anchor-horizontal:
                    column;mso-height-rule:exactly'><span lang=ES-GT style='font-family:"Arial",sans-serif;
                    mso-fareast-font-family:"Times New Roman";color:black;mso-font-kerning:0pt;
                    mso-ligatures:none;mso-ansi-language:ES-GT'>&nbsp;</span><span lang=ES-GT
                    style='font-size:12.0pt;font-family:"Times New Roman",serif;mso-fareast-font-family:
                    "Times New Roman";mso-font-kerning:0pt;mso-ligatures:none;mso-ansi-language:
                    ES-GT'><o:p></o:p></span></p>
                    </td>
                    </tr>
                    <tr style='mso-yfti-irow:1;mso-yfti-lastrow:yes'>
                    <td style='padding:0cm 0cm 0cm 0cm'>
                    <p class=MsoNormal style='margin-bottom:12.0pt;line-height:normal;mso-element:
                    frame;mso-element-frame-hspace:2.25pt;mso-element-wrap:around;mso-element-anchor-vertical:
                    paragraph;mso-element-anchor-horizontal:column;mso-height-rule:exactly'><b><span
                    lang=ES-GT style='font-family:"Arial",sans-serif;mso-fareast-font-family:
                    "Times New Roman";color:black;mso-font-kerning:0pt;mso-ligatures:none;
                    mso-ansi-language:ES-GT'>&nbsp;</span></b><span lang=ES-GT style='font-size:
                    12.0pt;font-family:"Times New Roman",serif;mso-fareast-font-family:"Times New Roman";
                    mso-font-kerning:0pt;mso-ligatures:none;mso-ansi-language:ES-GT'><o:p></o:p></span></p>
                    <p class=MsoNormal style='margin-bottom:12.0pt;line-height:normal;mso-element:
                    frame;mso-element-frame-hspace:2.25pt;mso-element-wrap:around;mso-element-anchor-vertical:
                    paragraph;mso-element-anchor-horizontal:column;mso-height-rule:exactly'><b><span
                    lang=ES-GT style='font-family:"Arial",sans-serif;mso-fareast-font-family:
                    "Times New Roman";color:black;mso-font-kerning:0pt;mso-ligatures:none;
                    mso-ansi-language:ES-GT'>Su consentimiento para el tratamiento de sus datos
                    personales</span></b><span lang=ES-GT style='font-size:12.0pt;font-family:
                    "Times New Roman",serif;mso-fareast-font-family:"Times New Roman";mso-font-kerning:
                    0pt;mso-ligatures:none;mso-ansi-language:ES-GT'><o:p></o:p></span></p>
                    <p class=MsoNormal style='margin-bottom:12.0pt;line-height:normal;mso-element:
                    frame;mso-element-frame-hspace:2.25pt;mso-element-wrap:around;mso-element-anchor-vertical:
                    paragraph;mso-element-anchor-horizontal:column;mso-height-rule:exactly'><span
                    lang=ES-GT style='font-family:"Arial",sans-serif;mso-fareast-font-family:
                    "Times New Roman";color:black;mso-font-kerning:0pt;mso-ligatures:none;
                    mso-ansi-language:ES-GT'>Consiento que mis datos personales sean tratados de
                    conformidad con los términos y condiciones informados en el presente aviso de
                    privacidad.</span><span lang=ES-GT style='font-size:12.0pt;font-family:"Times New Roman",serif;
                    mso-fareast-font-family:"Times New Roman";mso-font-kerning:0pt;mso-ligatures:
                    none;mso-ansi-language:ES-GT'><o:p></o:p></span></p>
                    </td>
                    </tr>
                </table>
                <p class=MsoNormal><span lang=ES-GT style='mso-ansi-language:ES-GT'><o:p>&nbsp;</o:p></span></p>
            </div>
        </section>
        <!-- end: SECTION GREY -->
    </div>
</template>
<style>
    form{
        margin-top: 1rem;
    }
</style>
<script>
/* eslint-disable */
import options from "../validations/validations.js";
Vue.use(VueForm, options);
import HeaderComponent from './components/HeaderComponent'
export default {
  name: 'Index',
  components: {
    HeaderComponent
  },
  data() {
      return {
        formstate: {},
        model: {
            email: '',
            password: '',
            captchaToken: '',
        },
        hostname: '',
        logo: 'images/logo-etc.png'
      }
  },

  methods: {
    async onSubmit() {
        if (this.formstate.$invalid) {
            return;
        } else {
            this.openLoading()
            grecaptcha.ready(() => {
                grecaptcha.execute('6Lf6nd4ZAAAAAF0miQR-oaid9l9VrrUbkeNtSSGW', {action: 'submit'}).then(async (token) => {
                    this.model.captchaToken = token
                    await axios.post(apiKS + 'api/login', this.model)
                        .then( async response => {
                            if(response.data.id == 0 && response.data.token == '') {
                                Swal.close()
                                Swal.fire({
                                    icon: 'error',
                                    title: 'Oops...',
                                    text: response.data.message,
                                })
                            }
                            if(response.data.id > 0 && response.data.token != '') {
                                Swal.close()
                                localStorage.tokenETCSistema = 'Bearer ' + response.data.token
                                headers = {
                                    'Content-Type': 'application/json',
                                    Authorization: localStorage.tokenETCSistema
                                }
                                await axios.get(apiKS + 'api/user', { headers })
                                    .then( response => {
                                        localStorage.userETC = response.data.userEncrypted
                                        axios.post(apiKS + 'api/userdecrypt', {usuario: localStorage.userETC}, { headers })
                                            .then( async response => {
                                                user = JSON.parse(response.data.user)
                                                this.$store.commit('setUsuario', user)
                                                localStorage.user = JSON.stringify(user);
                                                this.$store.commit('setProductos', [])
                                                //vaciamos el carrito
                                                await axios.post(apiKS + 'api/empty-cart', {}, { headers })
                                                    .then(response => {
                                                        router.replace('/productos')
                                                    })
                                                    .catch(error => {
                                                        this.catchError()
                                                    })
                                                // axios.post(apiKS + 'api/crea-carrito', this.$store.state.carrito, { headers })
                                                // .then( response => {
                                                // } )
                                                // .catch(error => {
                                                //     this.catchError(error)
                                                // })
                                            }).catch(error => {
                                                this.catchError(error)
                                            })
                                    })
                            }
                        }).catch(error => {
                            this.catchError()
                        })
                })
            })
        }
    }
  },
  mounted() {
    const script = document.createElement('script')
    script.src = 'js/functions.js'
    document.body.appendChild(script)

    if(this.$route.query.logout == 1){
        this.$store.commit('clearStoreData', null)
        localStorage.clear()
        headers = null
    }
  },
  created() {

  },
    beforeMount() {
    }
}
</script>
