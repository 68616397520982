<template>
    <div class="body-inner">
        <header-component></header-component>
        <section id="page-title" class="text-light" data-bg-parallax="images/compras_bg.jpg">
            <div class="container">
                <div class="page-title">
                    <h1>Carrito de compras</h1>
                    <span>Detalle de compras</span>
                </div>
                <div class="breadcrumb">
                    <ul>
                        <li><a href="#">Inicio</a>
                        </li>
                        <li><a href="#">Clientes</a>
                        </li>
                        <li class="active"><a href="#">Carrito</a>
                        </li>
                    </ul>
                </div>
            </div>
        </section>
        <!-- SHOP CART -->
        <section id="shop-cart">
            <div class="container">
                <div class="shop-cart" v-if="$store.getters.productos.length">
                    <div class="table table-sm table-striped table-responsive">
                        <table class="table">
                            <thead>
                                <tr>
                                    <th class="cart-product-remove"></th>
                                    <th class="cart-product-thumbnail">Producto</th>
                                    <th class="cart-product-price" align="right">Precio unitario</th>
                                    <th class="cart-product-quantity">Cantidad</th>
                                    <th class="cart-product-subtotal" align="right">Total</th>
                                </tr>
                            </thead>
                            <tbody>
                                <template v-for="(producto, index) in $store.getters.productos">
                                    <tr :key="index">
                                        <td>
                                            <div class="cart-product-thumbnail-name">
                                                <a href="#" @click.prevent="deleteProduct(producto, $store.getters.productos)">
                                                    <i class="fa fa-times"></i>
                                                </a>
                                            </div>
                                        </td>
                                        <td>{{ producto.name }} {{ producto.level }} </td>
                                        <td>{{ producto.sale_price | currency }}</td>
                                        <td class="cart-product-quantity">
                                            <div class="quantity">
                                                <input type="button" class="minus" value="-" @click.prevent="changeQuantity(producto, 0)">
                                                <input type="number" class="qty" v-model="producto.quantity" name="quantity" @focusout="calculaTotal($store.getters.productos)" style="padding:0">
                                                <input type="button" class="plus" value="+" @click.prevent="changeQuantity(producto, 1)">
                                            </div>
                                        </td>
                                        <td>{{ producto.quantity * producto.sale_price | currency }}</td>
                                    </tr>
                                </template>
                            </tbody>
                        </table>
                    </div>
                    <div class="row">
                        <div class="col-lg-4"><router-link to="/productos" type="button" class="btn btn-light">Volver al catálogo</router-link>
                           
                        </div>
                        <div class="col-lg-8 text-right">
                            
                        </div>
                    </div>
                    <div class="row">
                        <hr class="space"/>
                        <div class="col-lg-6"></div>
                        <div class="col-lg-6 p-r-10 ">
                            <div class="table-responsive">
                                <!-- <h4>Subtotal</h4> -->
                                <table class="table">
                                    <tbody>
                                        <tr>
                                            <td class="cart-product-name">
                                                <strong>Total</strong>
                                            </td>
                                            <td class="cart-product-name text-right">
                                                <span class="amount color lead"><strong>{{ $store.getters.total | currency }}</strong></span>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <router-link to="/checkout" class="btn icon-left float-right" v-if="$store.getters.productos.length"><span>PROCEDER AL PAGO</span></router-link>
                        </div>
                    </div>
                </div>
                <div v-else>
                    <h3>No hay productos en el carrito</h3>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
/* eslint-disable */
import HeaderComponent from './components/HeaderComponent'
export default {
  name: 'Carrito',
  components: {
    HeaderComponent
  },
  data() {
      return {
        model: {
            imagen: '',
        },
        total: 0,
        levels: {},
        productos: []
      }
  },

  methods: {
    async calculaTotal(productos) {
        let total = 0
        let subtotal = 0
        let flag = true
        productos.forEach((producto) => {
            if(user.tipo_usuario == 2 || user.tipo_usuario == 4){
                if(producto.quantity > this.levels[producto.level_id]){
                    flag = false
                    producto.quantity = this.levels[producto.level_id]
                    Swal.fire({
                        title: 'Aviso',
                        text: "La cantidad de productos no puede exceder el número de estudiantes del nivel.",
                        icon: 'warning',
                        confirmButtonColor: '#3085d6',
                        confirmButtonText: 'Ok',
                    })
                }
            }
            subtotal = producto.quantity * producto.sale_price
            total = total + subtotal

            if(producto.quantity <= 0){
                this.deleteProduct(producto, this.$store.getters.productos)
            }
        })
        if(flag){
            this.$store.commit('setTotal', total)
            await this.updateDataNoForm(`api/update-products-cart`, {productos: productos}, '', '', null, 0, 2, null)
        }
    },

    async changeQuantity(producto, accion) {
        if(accion == 0){
            if((producto.quantity - 1) <= 0)
                producto.quantity = 1
            else
                producto.quantity --
        }
            
        if(accion == 1){
            if(user.tipo_usuario == 2 || user.tipo_usuario == 4){
                if(producto.quantity < this.levels[producto.level_id]){
                    producto.quantity ++
                }else{
                    return Swal.fire({
                        title: 'Aviso',
                        text: "La cantidad de productos no puede exceder el número de estudiantes del nivel.",
                        icon: 'warning',
                        confirmButtonColor: '#3085d6',
                        confirmButtonText: 'Ok',
                    })
                }
            }else{
                producto.quantity ++
            }
        }

        if(this.$store.getters.usuario) {
            //await this.updateDataNoForm(`api/carrito/${producto.product_id}`, producto, '', '', null, 0, 2, null)
        }

        this.calculaTotal(this.$store.getters.productos)
    },

    async deleteProduct(producto, productos) {
        const index = productos.findIndex(element => element.product_id == producto.product_id)
        productos.splice(index, 1)

        if(this.$store.getters.usuario) {
            this.openLoading()
            await axios.delete(apiKS + `api/carrito/${producto.product_id}?tipo=2`, { headers })
            .then((response) => {
                Swal.close()
            })
            .catch( error => {
                Swal.close()
                this.catchError(error)
            })
        }

        this.calculaTotal(this.$store.getters.productos)
    }
  },
  async mounted() {
    const script = document.createElement('script')
    script.src = 'js/functions.js'
    document.body.appendChild(script)
    setTimeout(() => {
        this.$store.getters.usuario.benefitiaries.forEach(e => {
            if(this.levels[e.level_id] > 0){
                this.levels[e.level_id]++
            }else{
                this.levels[e.level_id] = 1
            }
        })
    }, 300)

    if(this.$store.getters.productos.length){
        this.calculaTotal(this.$store.getters.productos)
    }
    
  },
}
</script>
